import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AlertService, PurchasesDetailsService ,AppService} from '../../_services/index';
import * as printJS from 'print-js';

@Component({
  selector: 'app-purchases-details',
  templateUrl: './purchases-details.component.html'
})
export class PurchasesDetailsComponent implements OnInit {
  UrlParams:any;
  purchases:any;
  purchasesHistory:any = {
    settingData:'',
    company:'',
    email:'',
    phone:'',
    purchase_code:'',
    supplier_id:'',
    purchase_info:'',
    date:'',
    due:'',
    subtotal:'',
    totalAmount:'',
  };
  showloding = true;
  lodingImage = false;
  constructor(
      public router: Router,
  		public activatedRoute: ActivatedRoute,
      private dataService:PurchasesDetailsService,
      private AppService:AppService,
			private alertService:AlertService
		) { }

  ngOnInit() {
   
    this.activatedRoute.params.subscribe(params => {
      this.UrlParams = params['id'];
    });

  	this.dataService.getPurchasesDetails(this.UrlParams)
      .subscribe(data => { 
            this.purchases = data['purchase'];
            this.purchasesHistory = {
                settingData:this.purchases.settingData,
                company:this.purchases.company,
                name:this.purchases.name,
                email:this.purchases.email,
                phone:this.purchases.phone,
                purchase_code:this.purchases.purchase_code,
                date:this.purchases.date,
                due:this.purchases.due,
                purchase_info:this.purchases.purchase_info,
                subtotal:this.purchases.subtotal,
                totalAmount:this.purchases.totalAmount,
            };
            setTimeout(() => {
                this.showloding = false;
                this.lodingImage = true;
            },500);
      });
      
  }

  printView(){ 
    printJS({ printable: 'printJS-form', type: 'html',scanStyles: true,style:'width:100%',maxWidth:1400,css: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'})
    return false;
  }

}
