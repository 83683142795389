import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SupplierService {

  constructor(private http:HttpClient) { }

  save(data){
  	return this.http.post('/api/supplier-save',data);

  }

  getAllSupplier(){
    return  this.http.get('/api/get-all-supplier');
  }

  getSupplier(id){
    return this.http.post('/api/get-supplier',{id:id});
  }

  supplierUpdate(data){
    return this.http.post('/api/supplier-update',data);
  }
  
  getSupplierInfo(id){
    return this.http.post('/api/get-supplier-info',{id:id});
  }

}
