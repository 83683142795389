import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, SupplierService,AppService } from '../_services/index';
import {NgbModal, NgbActiveModal,NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from '../app.config';

class Supplier {
  id: number;
  company: string;
  name: string;
  email: string;
  phone: string;
  status: string;
}

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html'
})
export class SupplierComponent implements OnInit {
  @Input() allowMultiple: boolean;
  @Input() fileType: string;
  @Input() required: boolean;
  @Input() maxSizeInKb: number;
  @Output() onSelection = new EventEmitter<FileList>();
  DisplayedText: string = "";
  fileList:any;
  pdf = false;
  exl = false;
	dtOptions: DataTables.Settings = {}; //  DataTable
	dtTrigger = new Subject(); //  DataTable
	supplierList: Supplier[] = []; // Table Data list
  // supplierAddForm :FormGroup;
	getSupplier = {
	  id: '',
	  company: '',
	  name: '',
	  email: '',
    phone: '',
    status: '',
	  image: ''
	};
  setting = {currency:''};
	suplierInfo = {
    company:'',
    email:'',
    phone:'',
    status:'',
    image:'',
    purchase_code:'',
    date:'',
    purchaseInfo:[]
  };
	modalReference:NgbActiveModal;
	constructor(
          public router: Router,
          private dataService:SupplierService,
          private alertService: AlertService,
          private AppService: AppService,
          private modalService: NgbModal
        ) { }
	supplier = {
    id: '',
    company: '',
    name: '',
    email: '',
    phone: '',
    status: '',
    image: ''
  };
	options: NgbModalOptions = {size: 'lg'};
  modalTitle:string;
  showloding = true;
  lodingImage = false;
	ngOnInit() {

	    this.allSupplier();
      let settingData = JSON.parse(localStorage.getItem('setting'));
      this.setting = {currency:settingData.currency};
	}

	allSupplier(){
    this.loadShow();
    this.dataService.getAllSupplier()
        .pipe().subscribe(data => { 
          this.supplierList = data['supplier']; 
          this.dtTrigger.next(); // Data Table
          this.pdf = true;
          this.exl = true;
          this.loadHide();
        });
  	}
  	
  	save(val){
	    this.insertAction(val);
  	}

  	edit(id,content){
      this.loadShow();
      this.getSupplier.image = '';
	    this.dataService.getSupplier(id)
	        .pipe().subscribe(data => { this.getSupplier = data['supplier']; 

                this.supplier = {
                    id:this.getSupplier.id,
                    company:this.getSupplier.company,
                    name:this.getSupplier.name,
                    email:this.getSupplier.email,
                    phone:this.getSupplier.phone,
                    status:this.getSupplier.status,
                    image:this.getSupplier.image
                };
                this.loadHide();
                this.modalTitle = "Edit Supplier";
              this.modalReference = this.modalService.open(content);
          });
  	}

  	open(content) {
      this.getSupplier.image = '';
      this.DisplayedText = '';
      this.modalTitle = "Add Supplier";
      this.supplier = {id: '',company: '',name: '',email: '',phone: '',status: '1',image: ''};
    	this.modalReference = this.modalService.open(content);
  	}

  	openSupplierInfoMdal(id,supplierinfo){
      this.loadShow();
      this.suplierInfo.image = '';
  		this.dataService.getSupplierInfo(id)
        	.pipe().subscribe(data => {
                let supplier = data['supplier'];
                  this.suplierInfo = {
                    company:supplier.company,
                    email:supplier.email,
                    phone:supplier.phone,
                    status:supplier.status,
                    image:supplier.image,
                    purchase_code:supplier.purchase_code,
                    date:supplier.date,
                    purchaseInfo:data['purchase']
                  };
          });
          this.loadHide();
    	    this.modalReference = this.modalService.open(supplierinfo,this.options);
  	}

    fileChange(event: any) {
      this.fileList = event.target.files;
      // let filetypeToCompare = this.fileType.replace('*','');
      let hasFile = this.fileList && this.fileList.length > 0;
      if (hasFile) {
          var extension = this.fileList[0].name.substring(this.fileList[0].name.lastIndexOf('.'));
          // Only process image files.
          var validFileType = ".jpg , .png , .bmp";
          if (validFileType.toLowerCase().indexOf(extension) < 0) {
              alert("please select valid file type. The supported file types are .jpg , .png , .bmp");
            this.fileList = null;
            this.DisplayedText = "";
            return false;
          }
     
          if (this.fileList[0].size > 165535) {
              alert(`File size is more than 165 Kb`);
              this.fileList = null;
              this.DisplayedText = "";
              return false;
          }

          let multipleFile = this.fileList.length > 1;
          if (multipleFile) {
              this.DisplayedText = this.fileList.length + ' file(s) has been selected';
          }
          else {
              let file: File = this.fileList[0];
              this.DisplayedText = file.name;
          }
          this.onSelection.emit(this.fileList);
      }
  }

  insertAction(val){
    this.loadShow();
    let formData:FormData = new FormData();
    if(this.fileList !=undefined){
      let file: File = this.fileList[0];
      formData.append('file', file, file.name);
    }

    formData.append('company', this.supplier.company);
    formData.append('name', this.supplier.name);
    formData.append('email', this.supplier.email);
    formData.append('phone', this.supplier.phone);
    formData.append('status', this.supplier.status);

    if(val.id==undefined || val.id==''){
          this.dataService.save(formData)
              .pipe().subscribe(data =>{
                this.loadHide();
                this.modalReference.close();
                this.dtTrigger = new Subject(); //  DataTable
                this.allSupplier();
                this.alertService.success('Supplier Create successful', true);
            },error =>{
              this.alertService.error(error);
              this.loadHide();
            });
      }else{
          formData.append('id', val.id);
          this.dataService.supplierUpdate(formData)
              .pipe().subscribe(data =>{
                this.loadHide();
                this.modalReference.close();
                  this.dtTrigger = new Subject(); //  DataTable
                  this.allSupplier();
                  this.alertService.success('Supplier Update successful', true);
              },error =>{
              this.alertService.error(error);
              this.loadHide();
            });
      }
  }

  pdfExport(){
     window.open(appConfig.apiUrl+"/api/supplier-list-pdf", "_blank");
  }
  xlExport(){
     window.open(appConfig.apiUrl+"/api/supplier-list-excel", "_blank");
  }
  
  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  purchaseInvoiceDetails(id){
    this.modalReference.close();
    this.router.navigate(['purchases-details/' + id]);
  }
}
