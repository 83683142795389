import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
//import { CONNREFUSED } from 'dns';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { GridFilter } from '../shared/common_models';
import { DashboardService,SettingService, AlertService, FormatService } from '../_services/index';
import { SystemService } from '../_services/system.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@Component({
  templateUrl: 'dashboard.component.html'
})


export class DashboardComponent implements OnInit {

  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  
  barChartLabels:any[] = [];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;
  public errorSaleByWH:string="";
  public errorSaleByMeals:string="";
  public startDate:Date;
  public endDate:Date;




  // dropdown buttons
  public status: { isopen } = { isopen: false };
  dtStart_Req_By_Config: FlatpickrOptions = this.service.CommonDateConfig(); 
  dtEnd_Req_By_Config: FlatpickrOptions = this.service.CommonDateConfig();
  @ViewChild('stDate_Req_By') stDate_Req_By; 
  @ViewChild('edDate_Req_By') edDate_Req_By;
  fromDt_type: string; toDt_type: string;
  fromDt_OpT_type: string; toDt_OpT_type: string;
  gridFilter: Array<GridFilter> = [];
  lstSaleByWH:any=[]; 
  lstSaleByMeals:any=[]; 
  lstSaleByPay:any=[];
  
  
  data: any;
  customerPayment = {};
  sales:any;
  payment:any;
  expense:any;
  setting = {currency:''};
  totalCount = {
    product:'',
    damaged_product:'',
    customer:'',
    supplier:'',
    user:'',
    category:''
  }

  lastProduct:any;
  lastOrder:any;
  saleByWh: any;
  showloding = true;
  lodingImage = false;
  barChartData:any=[];
  sumSalesByWH:any;
  sumSalesByMeals:any;
  sumSalesByMealsQty:any;
  sumSalesByPay:any;

  

  

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  
  constructor(
              private dataService:DashboardService,
              private settingService:SettingService,
              private alertService: AlertService,
              private formatService: FormatService,
              public service: SystemService
            ) { 

              // var cdt = new Date(); 
              // cdt.setMonth(cdt.getMonth() - 1);
              // this.fromDt_type = this.fromDt_OpT_type = this.service.Date_Format(cdt, 'yyyy-MM-dd');
              // this.toDt_type = this.toDt_OpT_type = this.service.Date_Format(new Date(), 'yyyy-MM-dd');

              this.gridFilter.push(<GridFilter>{ DisplayText: "lblTradePoint", ColumnName: "wh", Type: "string", Is_Visible: true });
              this.gridFilter.push(<GridFilter>{ DisplayText: "lblAmount", ColumnName: "amount", Type: "number", Is_Visible: true,Is_Price:true });
            }


  
  ngOnInit() {
    // this.barChartData = [
    //     {data: [0], label: 'Purchases'},
    //     {data: [0], label: 'Sales'}
    //   ];
    
    //   this.dataService.getChartData().pipe().subscribe(data => {
    //     this.loadShow();

    //     let salesAmountArray = []; 
    //     let purchaseAmountArray = [];  
        
    //     if(data['status']==200)
    //     {
    //       let d = data['data'];
          
    //       for(let i=d.length-1; i>=0;i--)
    //       {
    //         this.barChartLabels.push(this.formatService.getFormattedDate2(d[i].date));
    //         salesAmountArray.push(d[i].sales);
    //         purchaseAmountArray.push(d[i].inputs);
    //       }
    //       this.barChartData = [
    //               {data: purchaseAmountArray, label: 'Purchases'},
    //               {data: salesAmountArray, label: 'Sales'}
    //             ];
            
    //     }
    //     else
    //     {
    //       this.alertService.error(data['mesg']);
    //     }
  
    //     // this.loadHide();
    //   });
    // var currentUser = JSON.parse(localStorage.getItem('currentUser'));





    this.dataService.getLastWP().pipe().subscribe(
      data => {
        
       

        this.fromDt_type = this.fromDt_OpT_type = this.service.Date_Format(data['startDate'], 'yyyy-MM-dd');
        this.toDt_type = this.toDt_OpT_type = this.service.Date_Format(data['endDate'], 'yyyy-MM-dd');

        window.setTimeout(() => { this.SetDateOptions(); }, 10);//Date Configs
        this.bindSale();
      }
        
    );

     
      let settingData = JSON.parse(localStorage.getItem('setting'));
      this.setting = {currency:settingData.currency};

     

     


       // this.bindSaleByWh();
      
  }

  SetDateOptions() {
    
    // this.dataService.getLastWP().pipe().subscribe(
    //   data => {
    //     // console.log(data);
    //   }
        
    // );

    //1. TicketCount_ByType
    this.stDate_Req_By.flatpickr.setDate(this.fromDt_type);
    this.stDate_Req_By.flatpickr.set("onChange", (dtarr, dt, instance) => {
        this.fromDt_type = dt;
        this.toDt_type = dt;
        this.edDate_Req_By.flatpickr.setDate(this.toDt_type);
       
    });
    this.edDate_Req_By.flatpickr.setDate(this.toDt_type);
    this.edDate_Req_By.flatpickr.set("onChange", (dtarr, dt, instance) => {
        this.toDt_type = dt;
    });

}

async bindSale() {
  try { 
    this.lstSaleByWH=[]; 
    this.lstSaleByMeals=[]; 
    this.lstSaleByPay=[];
    this.loadShow(); 
    
    this.dataService.getSalesTickets(this.fromDt_type,this.toDt_type).pipe().subscribe(
     

      data => {
        this.loadShow();
        //console.log(data);

        
        if(data['status']==200)
        {
          this.startDate=data['startDate']
          this.endDate=data['endDate']
          var array = data['data'];
          var result = [];

        

          //sales by paytypes

          array.forEach(element => {
            var pay =element["payments"];
            pay.forEach(p=>{             
              if(result.filter(x=>x.payType===p.paymentName).length===0)
                result.push({ payType: p.paymentName, amount: 0});
              result.find(o=>o.payType==p.paymentName).amount+=p.amount;
            });              
          });

          this.lstSaleByPay = result;
          var  sum=0;
          result.forEach(s=>{
            sum+=s.amount;
          });
          this.sumSalesByPay=sum;




          //sales by wh
          result=[];

          array.forEach(element => {
            if(result.filter(x=>x.wh===element.departmentName).length===0)
                result.push({ wh: element.departmentName, amount: 0});
              console.log(element);
              result.find(o=>o.wh==element.departmentName).amount+=element.totalAmount;
                          
          });

          this.lstSaleByWH = result;
          console.log(this.lstSaleByWH?.length);
          var  sum=0;
          result.forEach(s=>{
            sum+=s.amount;
          });
          this.sumSalesByWH=sum;


          //sales by meals

          
          var resultbymeal = [];
   
         

          array.filter(x=>x.isPaid).forEach(element => {            
            var ti1 =element["ticketItems"];
            ti1.forEach(p=>{
              if(resultbymeal.filter(x=>x.meal===p.menuItemName).length===0)
                resultbymeal.push({ meal: p.menuItemName, amount: 0, qty:0});
              if(!p.gifted && !p.voided)
                resultbymeal.find(o=>o.meal===p.menuItemName).amount+=p.price*p.quantity-p.discountAmount+p.serviceAmount;
              if(!p.voided)
                resultbymeal.find(o=>o.meal===p.menuItemName).qty+=p.quantity;
            });              
          });

          

          this.lstSaleByMeals = resultbymeal.sort(function (a, b) {
            return b.amount - a.amount;
          });
          var  sum=0;
          var  sumqty=0;
          resultbymeal.forEach(s=>{
            sum+=s.amount;
            sumqty+=s.qty;
          });
          this.sumSalesByMeals=sum;
          this.sumSalesByMealsQty=sumqty;
          
        }
        // else
        // {
          
        //   this.dataService.getSalesByWh(this.fromDt_type,this.toDt_type).pipe().subscribe(

        //     data => {
        //       this.loadShow();
        //       this.startDate=data['startDate']
        //       this.endDate=data['endDate']
        //       var array = data['data'];
        //       var result = [];
      
        //       array.reduce(function(res, value) {
        //                       if (!res[value.payType]) {
        //                         res[value.payType] = { payType: value.payType, amount: 0 };
        //                         result.push(res[value.payType])
        //                       }
        //                       res[value.payType].amount += value.amount;
                              
                        
        //                       return res;
        //                     }, {});
            
        //                     this.lstSaleByPay = result;
        //                     var  sum=0;
        //                     result.forEach(s=>{
        //                       sum+=s.amount;
        //                     });
        //                     this.sumSalesByPay=sum;
      
        //       this.loadHide();  
        //       this.bindSaleByMeal();
        //     }
        //   );
        // }
      
      this.loadHide();
      }
    );

    
    
    // this.dataService.getSales1(this.fromDt_type,this.toDt_type).pipe().subscribe(
    //   data => {
        
             

    //           this.loadShow();  

    //           if(data['status']==200)
    //           {
    //             //console.log(data);
    //             this.startDate=data['startDate']
    //             this.endDate=data['endDate']
    //             var array = data['data'];
                
    //             var result = [];

    //             array.reduce(function(res, value) {
    //               if (!res[value.wh]) {
    //                 res[value.wh] = { wh: value.wh, amount: 0 };
    //                 result.push(res[value.wh])
    //               }
    //               res[value.wh].amount += value.amount;
                  
            
    //               return res;
    //             }, {});

    //             this.lstSaleByWH = result;
    //             var  sum=0;
    //             result.forEach(s=>{
    //               sum+=s.amount;
    //             });
                
    //             this.sumSalesByWH=sum;

    //             //SaleByMeals

    //             var resultbymeal = [];

    //             array.reduce(function(res, value) {
    //               if (!res[value.meal]) {
    //                 res[value.meal] = { meal: value.meal, qty: 0,amount:0 };
    //                 resultbymeal.push(res[value.meal])
    //               }
    //               res[value.meal].amount += value.amount;
    //               res[value.meal].qty += value.qty;
                  
            
    //               return res;
    //             }, {});

    //             this.lstSaleByMeals = resultbymeal.sort(function (a, b) {
    //               return b.amount - a.amount;
    //             });
    //             var  sum=0;
    //             var  sumqty=0;
    //             resultbymeal.forEach(s=>{
    //               sum+=s.amount;
    //               sumqty+=s.qty;
    //             });
    //             this.sumSalesByMeals=sum;
    //             this.sumSalesByMealsQty=sumqty;               
                
    //           }
    //           else
    //           {
    //             this.alertService.error(data['mesg']);
    //             this.errorSaleByWH=data['mesg'];
    //           }



    //           this.loadHide();             

             
    //     });



       
  } 
  catch (e) {  

  }
} 



// async bindSaleByMeal() {
//   try { 
//     this.lstSaleByMeals=[]; 
//     this.loadShow();

   
    
//     this.dataService.getSales1(this.fromDt_type,this.toDt_type).pipe().subscribe(
//       data => {
        
             

//               this.loadShow();  

//               if(data['status']==200)
//               {
//                 //console.log(data);
//                 //this.startDate=data['startDate']
//                 //this.endDate=data['endDate']
//                 var array = data['data'];
                
//                 var result = [];

                
//                 //SaleByMeals

//                 var resultbymeal = [];

//                 array.reduce(function(res, value) {
//                   if (!res[value.meal]) {
//                     res[value.meal] = { meal: value.meal, qty: 0,amount:0 };
//                     resultbymeal.push(res[value.meal])
//                   }
//                   res[value.meal].amount += value.amount;
//                   res[value.meal].qty += value.qty;
                  
            
//                   return res;
//                 }, {});

//                 this.lstSaleByMeals = resultbymeal.sort(function (a, b) {
//                   return b.amount - a.amount;
//                 });
//                 var  sum=0;
//                 var  sumqty=0;
//                 resultbymeal.forEach(s=>{
//                   sum+=s.amount;
//                   sumqty+=s.qty;
//                 });
//                 this.sumSalesByMeals=sum;
//                 this.sumSalesByMealsQty=sumqty;               
                
//               }
//               else
//               {
//                 this.alertService.error(data['mesg']);
//                 this.errorSaleByWH=data['mesg'];
//               }



//               this.loadHide();             

             
//         });



       
//   } 
//   catch (e) {  

//   }
// } 




  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }

  
}
