import { Component, OnInit,Input,OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
// import { FormGroup, FormBuilder,FormArray, Validators, FormControl,NgModel,FormsModule  } from '@angular/forms';
import { AlertService, PurchaseService,AppService } from '../_services/index';
import {NgbDateStruct,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html'
})

export class PurchasesComponent implements OnInit {

	purchaseAddForm = null;
	constructor(
				public router: Router,
				private dataService:PurchaseService,
				private alertService: AlertService, 
				private AppService: AppService, 
				private parserFormatter: NgbDateParserFormatter
			) { }
	payment = {};
	allSupplier:any[] = [];
	productList:any[]=[];
	paymentTypeList = [{id:1,name:'cash'},{id:2,name:'check'},{id:3,name:'card'}];
	purchase = {
		purchaseCode:0,
		supplierName: "",
		date: {},
		allProduct: "",
		grandTotal: 0,
		receivedAmount:0,
		due:"",
		paymentType:"",
		products: [],
	};
	d = new Date();
    formatDate = moment(this.d).format('YYYY-MM-DD');
	randomnumber = moment(this.d).format('YYMMDDHHmmss');
	showloding = true;
  	lodingImage = false;
	ngOnInit() {

		this.product();
		this.supplier();		
		this.setDefultValue();
	}

	supplier(){
		this.dataService.getAllSupplier()
			.subscribe(data =>{
						this.allSupplier = data['supplier'];
					});
		
	}

	product(){
		this.loadShow();
		this.dataService.getAllProduct()
			.subscribe(data => { 
			this.productList =data['product'];
			this.loadHide();
		});
	}
	
	setDefultValue(){
		let date = this.formatDate.split('-');
		let setDate = {year:  Number(date[0]), month: Number(date[1]), day: Number(date[2])};
		this.purchase.purchaseCode = Number(this.randomnumber);
		this.purchase.date = setDate;
	}


	onChangeDate(event){
		let setDate = moment(event).format('YYYY-MM-DD');
		this.purchase.date = setDate;
	}

	selectProduct(event){

		if(event > 0){
			this.loadShow();
			this.dataService.getCategoryByPurchases(event)
				.subscribe(data => { 
					let item = data['product'];
	    			var product = {id:item.id,serial_number: item.serial_number, name: item.name, quantity: 1,purchase_price:item.purchase_price,total:null};
	    			this.purchase.products.push(product);
	    			// const control = <FormArray>this.purchaseAddForm.controls['products'];
					// control.push(this.createFormGroupArray(product));
					this.loadHide();
					this.itemChange();
	            });
		}
		
	}

	itemChange(index=''){
		let totalAmount=0;

		for (let key in this.purchase.products) {
			if(index){
				this.purchase.products[index].total = (this.purchase.products[index].purchase_price * this.purchase.products[index].quantity);
			}else{
				this.purchase.products[key].total = (this.purchase.products[key].purchase_price * this.purchase.products[key].quantity);
			}
			let num = Number(this.purchase.products[key].total);
			totalAmount += num;
		}
		
		setTimeout(() => {
            this.purchase.grandTotal = totalAmount;
        });
        this.amountChange();
	}

	amountChange(){
		setTimeout(() => {
             let totalDue = this.purchase.grandTotal - this.purchase.receivedAmount;
             this.purchase.due =  totalDue.toFixed(2);
        });
	}

    deleteTableRow(index:number) {
		// delete payoff from both the model and the FormArray
		this.purchase.products.splice(index, 1);
		// const control = <FormArray>this.purchaseAddForm.controls['products'];
        // control.removeAt(index);
        this.itemChange();
	}

    /* createFormGroupArray(product) {
		return new FormGroup({
			serial_number: new FormControl(product.serial_number),
			name: new FormControl(product.name),
			quantity: new FormControl(product.quantity),
			purchase_price: new FormControl(product.purchase_price),
			total: new FormControl(product.total)
		});
	} */

	createPurchase(){
		this.loadShow();
		let purchaseDate = Object(this.purchase.date);
		let date = this.parserFormatter.format(purchaseDate);
		this.purchase.date=date;
		this.dataService.createNewPurchase(this.purchase)
            .subscribe(data =>{
				this.loadHide();
              this.alertService.success('Purchases Create successful', true);
              this.router.navigate(['purchases-details/' + data['purchase_id']]);
            },error =>{
			  this.alertService.error(error);
			  this.loadHide();
            });
	}

	loadShow(){
	    this.showloding = true;
	    this.lodingImage = false;
	}

	loadHide(){
	    this.showloding = false;
		this.lodingImage = true;
	}

}
