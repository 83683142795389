import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MVFlowResponce } from '../models/MVFlow';
import { MVFlowDetailResponce } from '../models/MVFlowDetail';
import { WHResponce } from '../models/WhResponse';
import { ItemResponce } from '../models/ItemResponse';
import { SalesTicketsResponce } from '../models/SalesTickets';

@Injectable()
export class DashboardService {

  constructor(private http:HttpClient) { }

  totalCount(){
     return  this.http.get('/api/get-all-total-count');
  }

  getChartData(){
    return  this.http.get('/api/get-chart-data');
  }
  getSalesByWh(from: string,to:string){
    return  this.http.get('/api/get-salesbywh?stDate='+from+'&endDate='+to);
  }
  getSales1(from: string,to:string){
    return  this.http.get('/api/get-sales1?stDate='+from+'&endDate='+to);
  }
  
  getDailySales(){
    return  this.http.get('/api/get-daily-sales');
  }
  sumAllData(){
    return this.http.get('/api/get-all-dashboard-data');
  }

  getLastProduct(){
    return this.http.get('/api/get-latestProduct');
  }
  getMVFlow(from: string,to:string,itemid: number=0,whid: number=0)
  {
    return this.http.get<MVFlowResponce>('/api/get-mvflowavg?stDate='+from+'&endDate='+to+'&WHId='+whid+'&ItemId='+itemid);
  }
  getMVFlowDetail(from: string,to:string,itemName:string)
  {
    return this.http.get<MVFlowDetailResponce>('/api/get-mvflowavgdetail?stDate='+from+'&endDate='+to+'&itemName='+itemName);
  }

  getWhList()
  {
    return this.http.get<WHResponce>('/api/get-whlist');
  }

  getItemList()
  {
    return this.http.get<ItemResponce>('/api/get-Itemlist');
  }

  getLastWP(){
    return  this.http.get('/api/get-lastwp');
  }
  getSalesTickets(from: string,to:string)
  {
    
    return this.http.get<SalesTicketsResponce>('/api/get-salestickets?stDate='+from+'&endDate='+to);
  }
}
