<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <form method="post" enctype="multipart/form-data" class="form-horizontal" (ngSubmit)="createPurchase()" #f="ngForm">
            <div class="row">
                <!--/.col-->
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-block">
                            <h5 class="card-title">Purchases Products</h5> 
                            <div class="form-group row">
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <!-- <label for="category" class="col-4 form-control-label">Product</label> -->
                                        <div class="col-md-12">
                                            <select class="form-control" id="allProduct" name="allProduct" [(ngModel)]="purchase.allProduct" (ngModelChange)="selectProduct($event)">
                                                <option value="" selected disabled>Purchase A Product</option>
                                                <option *ngFor="let item of productList" value="{{item.id}}">{{item.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="table-responsive">
                                        <table class="table table-bordered" id="add-product">
                                            <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Serial</th>
                                                  <th>Name</th>
                                                  <th>Quantity</th>
                                                  <th>Unit Price</th>
                                                  <th>Total</th>
                                                  <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let product of purchase.products; let i=index" class="panel panel-default">
                                                    <td>{{i + 1}}</td>
                                                    <td><input type="text" class="form-control form-control-sm" name="serial_number_{{i}}" readonly [(ngModel)]="purchase.products[i].serial_number"></td>
                                                    <td><input type="text" class="form-control form-control-sm" name="name_{{i}}" [(ngModel)]="purchase.products[i].name"></td>
                                                    <td><input type="number" min="1" class="form-control form-control-sm" name="quantity_{{i}}" [(ngModel)]="purchase.products[i].quantity" (change)="itemChange(i)"></td>
                                                    <td><input type="number" class="form-control form-control-sm" name="purchase_price_{{i}}" [(ngModel)]="purchase.products[i].purchase_price" (change)="itemChange(i)"></td>
                                                    <td><input type="text" class="form-control form-control-sm" name="total" name="total_{{i}}" [(ngModel)]="purchase.products[i].total" readonly></td>
                                                    <td><button (click)="deleteTableRow(i)" class="btn btn-danger btn-sm">x</button></td> 
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 px-3">
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Supplier</legend>
                                        <div class="form-group row">
                                            <label for="purchaseCode" class="col-5 col-form-label">Purchase Code</label>
                                            <div class="col-7">
                                                <input class="form-control" type="text" id="purchaseCode" name="purchaseCode" required [(ngModel)]="purchase.purchaseCode">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="supplierName" class="col-5 form-control-label">Supplier <span>*</span> </label>
                                            <div class="col-7">
                                                <select class="form-control" id="supplierName" name="supplierName" [(ngModel)]="purchase.supplierName" required>
                                                    <option value="" selected disabled>Please Select</option>
                                                    <option *ngFor="let item of allSupplier" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="date" class="col-5 col-form-label">Date <span>*</span> </label>
                                            <div class="col-7">
                                                <div class="input-group">
                                                  <input class="form-control" placeholder="yyyy-mm-dd" name="date" id="date" required [(ngModel)]="purchase.date" ngbDatepicker #d="ngbDatepicker">
                                                  <button class="input-group-addon" (click)="d.toggle()" type="button">
                                                    <i class="fa fa-calendar"></i>
                                                  </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-md-6 px-3">
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Payment</legend>
                                        <div class="form-group row">
                                            <label for="grandTotal" class="col-5 form-control-label">Grand Total</label>
                                            <div class="col-7">
                                                <input class="form-control form-control-sm" type="text" id="grandTotal" name="grandTotal" readonly [(ngModel)]="purchase.grandTotal">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="amount" class="col-5 form-control-label">Amount <span>*</span></label>
                                            <div class="col-7">
                                                <input class="form-control form-control-sm" type="number" id="receivedAmount" name="receivedAmount" required [(ngModel)]="purchase.receivedAmount" (ngModelChange)="amountChange()">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="due" class="col-5 form-control-label">Due</label>
                                            <div class="col-7">
                                                <input class="form-control form-control-sm" type="text" id="due" name="due" readonly [(ngModel)]="purchase.due">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="type" class="col-5 form-control-label">Payment Type <span>*</span> </label>
                                            <div class="col-7">
                                                <select class="form-control form-control-sm" type="text" id="paymentType" name="paymentType" required [(ngModel)]="purchase.paymentType">
                                                    <option value="">Please Select</option>
                                                    <option *ngFor="let item of paymentTypeList" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-info btn-sm" [disabled]="!f.form.valid" >Create New Purchase</button>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>