import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
// import  { AuthenticationService } from '../service/authentication/authentication.data.service';
import { AlertService, AuthenticationService, SettingService} from '../_services/index';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { KeyValueString } from '../shared/common_models';
import { SystemService } from '../_services/system.service';

// import $ from 'jquery/dist/jquery';


@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  LanguageList: Array<KeyValueString> = []; 
  SelectedLang: KeyValueString;

  username: string;
    password: string;
    token:string;
    // textMesg:any;
    show: boolean = false;

    model: any = {};
    loading = false;
    returnUrl: string;
    setting = {image:''};



  constructor(
            private route: ActivatedRoute,
            public router: Router,
            private dataService:AuthenticationService,
            private setingService:SettingService,
            private alertService: AlertService,
            public service: SystemService
          ) { 

           
    }
    
    async ngOnInit() { 
      // await this.bindLanguages();   
      // await this.GetDefaultLanguage();
      this.bindLanguages();   
      this.GetDefaultLanguage();
      
      if (localStorage.getItem('currentUser') !=null) {
        this.router.navigate(['/dashboard']);
      }
      // get return url from route parameters or default to '/'
      // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      this.setingService.getSettingData()
            .pipe().subscribe(data => { 
                        this.setting = {image:data['setting'].image}                        
            });
      
        
    }   
    onLoggedin() {
    	this.loading = true;
      //this.alertService.error('test');
      
	    
      var UserInput = {
        'email': this.model.username,
	    	'password': this.model.password
	    };
      
      if(this.model.username !=undefined  && this.model.password != undefined && this.SelectedLang != undefined){

	      this.dataService.login(UserInput)
	          .pipe().subscribe(
	              data => {
	                  if(data.status==400){
	                  	this.show = true;
	                  	//this.textMesg = data.mesg;
	                    this.loading = false;                      
                      this.alertService.error(this.service.Translator.instant(data.mesg));
                    }else if(data.status==500){
                      this.show = true;
                      // this.textMesg = data.mesg;
                      this.loading = false;
                      this.alertService.error(this.service.Translator.instant(data.mesg));
                    }else if(data.status==300){ 
                      this.show = true;
                      // this.textMesg = data.mesg;
                      this.loading = false;
                      this.alertService.error(this.service.Translator.instant(data.mesg));
	                  }else{
                      this.loading = false;
                      this.router.navigate(['/dashboard']);
	                  }
	              },
	              error => {

	                  this.alertService.error(error);
                    this.loading = false;
                    
	              });
      }
      else
      {
        if(this.SelectedLang == undefined)
          this.alertService.error(this.service.Translator.instant("msgSelectlanguage"));
        else
          this.alertService.error(this.service.Translator.instant("msgEmptyUsernameOrPassword"));
        this.loading = false;
      }    
    }

    async bindLanguages() {

       await this.service.get_Languages().pipe().subscribe(data=>
        {
          this.LanguageList = data['data'];
          let lang_storage = localStorage.getItem("lang");
          if (lang_storage) {
            this.SelectedLang = this.LanguageList.find(d => d.value == lang_storage);
          }
        });
      
      // this.LanguageList = await this.service.Get_Languages();
      // console.log( this.LanguageList);
      // let lang_storage = localStorage.getItem("lang");
      // if (lang_storage) {
      //     this.SelectedLang = this.LanguageList.find(d => d.Value == lang_storage);
      // }
      

  }
    async GetDefaultLanguage() {
      try {
          
          this.setingService.getDefaultLanguage().pipe().subscribe(data => {
             if (data) {
              let lang = data['lang'];
              
              let lang_storage = localStorage.getItem("lang");
              
         
              
              if (lang_storage) {
                  lang = lang_storage;

                  this.SelectedLang = this.LanguageList.find(d => d.value == lang);


                  this.service.CL = this.SelectedLang.value;
                  this.service.Translator.use(this.SelectedLang.value);
                  localStorage.setItem("lang", this.SelectedLang.value);
                  //this.LoginForm.controls["language"].setValue(this.SelectedLang.Value);
              }
              else {
                  
                  
                  let item = this.LanguageList.find(d => d.value == lang);
                 
                  this.ChangeLang(item);
              }
          }                     
});
          

         
      } catch (e) { 
        
      }
  }

  async ChangeLang(item: any) {
    if (this.service.CL != item.value) {
      
        let res = this.setingService.getTranslate({ params: { lang: item.value }}).pipe().subscribe(data=>
         {
           
          this.service.Translator.set_data(data['data']);
          //dummy set for reflect new changes            
          this.service.CL = 'dummy';
          setTimeout(() => { this.service.CL = item.value; });

         } );
        
    }

    this.SelectedLang = item;
    this.service.CL = this.SelectedLang.value;
    this.service.Translator.use(this.SelectedLang.value);
    localStorage.setItem("lang", this.SelectedLang.value);
    //this.LoginForm.controls["language"].setValue(this.SelectedLang.Value);

    //setTimeout(() => { this.service.Set_RTL(); })
}

}
