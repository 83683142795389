<div id="loding" [ngClass]="{ 'loading-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div class="card-block-title">
							<h5 class="card-title">Damaged Product</h5>
                    		<button id="add_btn" type="button" class="btn btn-info pull-right" (click)="open(content)"> Add Damaged Product</button>
                    	</div>
						<p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p>
						<div class="table-responsive">
							<table id="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-hover" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Code</th>
										<th>Name</th>
										<th>Category</th>
										<th>Purchase Price</th>
										<th>Quantity</th>
										<th>Date</th>
										<th style="width: 13%;" class="action">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of DamagedProductList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.serial_number}}</td>
										<td>{{item.name}}</td>
										<td>{{item.categoryName}}</td>
										<td>{{item.purchase_price}} {{setting.currency}}</td>
										<td>{{item.quantity}}</td>
										<td>{{item.date}}</td>
										<td class="action"><button type="button" class="btn btn-outline-primary btn-sm" (click)="edit(this.DamagedProductList[i].id,content)"><i class="fa fa-pencil fa-lg mt-1"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<!--/.col-->
		</div>
	</div>
	<ng-template #content let-c="close" let-d="dismiss" id="customer-add">
		<div class="modal-info" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{modalTitle}}</h4>
					<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body">
					<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
						<div class="form-group row">
							<label for="name" class="col-5 col-form-label">Product Name <span>*</span></label>
							<div class="col-7">
								<select class="form-control" id="allProduct" name="allProduct" required  [(ngModel)]="product.allProduct">
                                    <option value="" selected disabled>Select A Product</option>
                                    <option *ngFor="let item of productList" value="{{item.id}}">{{item.name}}</option>
                                </select>
							</div>
						</div>
						<div class="form-group row">
							<label for="quantity" class="col-5 col-form-label">Quantity <span>*</span></label>
							<div class="col-7">
								<input class="form-control" type="number" id="quantity" name="quantity" required [(ngModel)]="product.quantity">
							</div>
						</div>
						<div class="form-group row">
							<label for="note" class="col-5 col-form-label">Note</label>
							<div class="col-7">
								<textarea class="form-control" type="text" id="note" name="note" [(ngModel)]="product.note"></textarea>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">Close</button>
					<button type="submit" class="btn btn-outline-primary" [disabled]="!f.form.valid" (click) ="save(this.product)">Save</button>
				</div>
			</div>
		</div>
	</ng-template>
</div>







