<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loading.gif">
</div>


<!-- <p>
{{ theNumber | number:'1.0-1'}}
</p> -->


<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn">
   
  <div class="row">

    <div class="col-lg-6 grid-margin">
      <div class="card full-height">
       
       <!-- <div class="card-header">  
         
        
       
          <h3 class="card-title float-left">{{'lblSales'| translate:service.CL}}</h3>
          
          <div class="row float-right ">
            <div class="form-group">
              {{'lblstartDate'| translate:service.CL}}
              <div class="table-filter">
                  <ng2-flatpickr #stDate_Req_By [config]="dtStart_Req_By_Config" altInputClass="form-control"></ng2-flatpickr>
              </div>
            </div>
              <div class="form-group">
                {{'lblendDate'| translate:service.CL}}
                <div class="table-filter">
                    <ng2-flatpickr #edDate_Req_By [config]="dtEnd_Req_By_Config" altInputClass="form-control"></ng2-flatpickr>
                </div>
            </div>
          </div> 
            
        </div>  -->                   



          
        <div class="card-body min-height">
            <!--Start Common Grid-->
            <div class="row">
              
              <div class="col-sm-5">
                <h3 class="card-title">{{'lblSales'| translate:service.CL}}</h3>
                <div class="small text-muted" *ngIf="lstSaleByPay?.length > 0">{{startDate | date: 'yyyy-MM-dd HH:mm'}}-{{endDate | date: 'yyyy-MM-dd HH:mm'}}</div>
              </div>
             
              <div class="col-sm-7 d-md-block">
                <div class="form-group  float-right"> {{'lblendDate'| translate:service.CL}}
                  <div class="table-filter summary input-group datepicker mr-2 ml-1">
                      <ng2-flatpickr #edDate_Req_By [config]="dtEnd_Req_By_Config" altInputClass="form-control"></ng2-flatpickr>
                  </div>
                </div>

                <div class="form-group float-right">{{'lblstartDate'| translate:service.CL}}
                  <div class="table-filter summary input-group datepicker mr-2 ml-1">
                      <ng2-flatpickr #stDate_Req_By [config]="dtStart_Req_By_Config" altInputClass="form-control"></ng2-flatpickr>
                  </div>
                </div>
                
              <div class="form-group float-left">  
                <!-- <div class="table-filter summary input-group datepicker mr-2 ml-1">     -->
                  <button type="button" class="col-xs-1 btn  btn-success " (click)="bindSale()" >Refresh</button>
                <!-- </div> -->
              </div>
              </div>
            </div>
            
            <div style="position:relative;">
                <table class="table table-bordered table-striped table-hover"  *ngIf="lstSaleByPay?.length > 0">  
                  <thead class="thead-dark">  
                    <tr>  
                      <th> {{'lblname'| translate:service.CL}}</th>  
                      <th class="text-right">{{'lblamount'| translate:service.CL}} </th>  
                    </tr>  
                  </thead>  
                  <tbody>  
                    <tr *ngFor="let sale of lstSaleByPay">  
                      <td>{{sale.payType}}</td>  
                      <td class="text-right">{{sale.amount | number}}</td>  
                    </tr>  
                    <tr>  
                        <td colspan="1"><b>{{'lbltotal'| translate:service.CL}}:</b> </td>  
                        <td colspan="1" class="text-right"><b>{{sumSalesByPay  | number}}</b> </td>  
                      </tr>  
                  </tbody>  
                </table>  



                



                <div *ngIf="!showloding && lstSaleByPay.length == 0">
                    <span>{{'lblNoData'| translate:service.CL}}</span>
                    <span style="color:red">{{errorSaleByWH}}</span>
                </div>
            </div>
          
        </div>

        <!-- Sale by WH -->
        
        <div class="card-body min-height">
          <h3 class="card-title"  *ngIf="lstSaleByWH?.length > 0">{{'lblTotalSales'| translate:service.CL}}</h3>
          <div style="position:relative;">
            
           <table class="table table-bordered table-striped table-hover"  *ngIf="lstSaleByWH?.length > 0">  
                  <thead class="thead-dark">  
                    <tr>  
                      <th> {{'lblname'| translate:service.CL}}</th>  
                      <th class="text-right">{{'lblamount'| translate:service.CL}} </th>  
                    </tr>  
                  </thead>  
                  <tbody>  
                    <tr *ngFor="let sale of lstSaleByWH">  
                      <td>{{sale.wh}}</td>  
                      <td class="text-right">{{sale.amount | number}}</td>  
                    </tr>  
                    <tr>  
                        <td colspan="1"><b>{{'lbltotal'| translate:service.CL}}:</b> </td>  
                        <td colspan="1" class="text-right"><b>{{sumSalesByWH  | number}}</b> </td>  
                      </tr>  
                  </tbody>  
            </table>   

            
           
        </div>

        </div>
        <!-- End Sale by WH -->

        <div class="card-body min-height">
          
          <div style="position:relative;">

              <table class="table table-bordered table-striped table-hover"  *ngIf="lstSaleByMeals?.length > 0">  
                <thead class="thead-dark">  
                  <tr>  
                    <th> {{'lblname'| translate:service.CL}}</th>  
                    <th class="text-right ">{{'lblqty' | translate:service.CL}} </th>
                    <th class="text-right d-none d-sm-table-cell" >{{'lblprice'| translate:service.CL}} </th>  
                    <th class="text-right">{{'lbltotal'| translate:service.CL}} </th>
                  </tr>  
                </thead>  
                <tbody>  
                  <tr *ngFor="let sale of lstSaleByMeals">  
                    <td>{{sale.meal}}</td>  
                    <td class="text-right ">{{sale.qty | number}}</td>
                    <td class="text-right d-none d-sm-table-cell">{{sale.amount/sale.qty | number}}</td> 
                    <td class="text-right">{{sale.amount | number}}</td> 
                    
                  </tr>  
                  <tr>  
                      <td colspan="1"><b>{{'lbltotal'| translate:service.CL}}:</b> </td>
                      <td colspan="1" class="text-right "><b>{{sumSalesByMealsQty  | number}}</b> </td>   
                      <td colspan="2" class="text-right"><b>{{sumSalesByMeals  | number}}</b> </td>  
                    </tr>  
                </tbody>  
              </table>  



              <div *ngIf="!showloding && lstSaleByMeals.length == 0">
                  <span>{{'lblNoData'| translate:service.CL}} </span>
                  <span style="color:red">{{errorSaleByMeals}}</span>
              </div>
          </div>
        
        </div>

      </div>
    </div>

    <!-- <div class="col-lg-6">
      <div class="card">
        <div class="card-block">
          <h6 class="card-title">Daily Report</h6>

          <div>
              <div style="display: block">
              <canvas baseChart
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>
</div>
