import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SalesService {

  constructor(private http:HttpClient) { }

  getAllCustomer(){
  	return this.http.get('/api/all-customer-list');
  }
  getCustomerDiscount(id){
  	return this.http.post('/api/get-customer-by-discount',{id:id});
  }
  getAllCategory(){
  	return this.http.get('/api/get-all-category');
  }
  
  getSubCategory(id){
    return this.http.post('/api/get-cat-by-subCategory',{id:id});
  }

  getCategoryByProduct(type,id){

    return this.http.post('/api/get-categoryByProduct',{cat:id,type:type});
  }

  getProductInfo(id){
    return this.http.post('/api/get-product-info',{id:id});
  }

  createSales(data){
  	return this.http.post('/api/create-new-sales',data);
  }

}