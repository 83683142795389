import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AlertService, SalesHistoryService,AppService } from '../../_services/index';
import { appConfig } from '../../app.config';

class sales {
  id: number;
  invoice_code: string;
  date: string;
  customerName: string;
  due: number;
  totalAmount: number;
}

@Component({
  selector: 'app-sales-history',
  templateUrl: './sales-history.component.html'
})
export class SalesHistoryComponent implements OnInit {
	dtOptions: DataTables.Settings = {}; //  DataTable
	dtTrigger = new Subject(); //  DataTable
	salesList: sales[] = []; // Table Data list
	salesHistory:any =[];
  setting = {currency:''};
	showloding = true;
  lodingImage = false;
  pdf = false;
  exl = false;
  print = false;
  constructor(
			public router: Router,
      private dataService: SalesHistoryService,
  		private AppService: AppService,
      private alertService:AlertService,
		) { }

  ngOnInit() {
    this.loadShow();
  	this.dataService.getAllsales()
      .pipe().subscribe(data => { 
        this.salesList = data['sales'];
        this.dtTrigger.next(); // Data Table
        this.pdf = true;
        this.exl = true;
        this.print = true;
        let settingData = JSON.parse(localStorage.getItem('setting'));
        this.setting = {currency:settingData.currency};
        this.loadHide();
      });
  }

  salesDetails(id){
		this.router.navigate(['sales-invoice-details/' + id]);
	}

	takePayment(id){
		this.router.navigate(['sales-take-payment/' + id]);
	}

  pdfExport(){
    window.open(appConfig.apiUrl+"/api/sales-history-list-pdf", "_blank");
  }
  xlExport(){
    window.open(appConfig.apiUrl+"/api/sales-history-list-excel", "_blank");
  }

  printTable() {
    var divToPrint = document.getElementById('table');
    var htmlToPrint = '' +
    '<style type="text/css">' +
    'table th, table td {' +
    'border:1px solid #000;' +
    'padding;0.5em;' +
    '}.action{display:none;}' +
    '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

  loadShow(){
		this.showloding = true;
		this.lodingImage = false;
	}

	loadHide(){
		this.showloding = false;
		this.lodingImage = true;
  }
  
}
