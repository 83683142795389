import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductService {

  constructor(private http:HttpClient) { }

  save(data){
    return this.http.post('/api/product-save', data);
  }

  getAllProduct(){
    return  this.http.get('/api/all-product');
  }

  getProduct(id){
    return this.http.post('/api/get-product-details',{id:id});
  }

  getCategory()
  {
    return this.http.get('/api/get-all-category');
  }

  getSubCategory(id){
    return this.http.post('/api/get-cat-by-subCategory',{id:id});
  }
  productUpdate(data){
    return this.http.post('/api/product-update',data);
  }

  getProductInfo(id){
    return this.http.post('/api/get-product-details',{id:id});
  }

}
