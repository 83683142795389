import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DamagedProductService {

  constructor(private http:HttpClient) { }

  save(data){
    return this.http.post('/api/damaged-product-save', data);
  }

  allDamagedProduct(){
    return  this.http.get('/api/all-damaged-product');
  }

  getAllProduct(type){
    return this.http.post('/api/get-all-product-by-damaged',{type:type});
  }

  getDamagedProduct(id){
    return this.http.post('/api/get-damaged-product',{id:id});
  }

  damagedProductUpdate(data){
    return this.http.post('/api/damaged-product-update',data);
  }

}
