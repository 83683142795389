import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService, DashboardService } from 'src/app/_services';
import { MVFlow } from 'src/app/models/MVFlow';
import { MatTableDataSource } from '@angular/material/table';
import { SystemService } from 'src/app/_services/system.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ItemDTO } from 'src/app/models/ItemResponse';
import { WHDTO } from 'src/app/models/WhResponse';

@Component({
  selector: 'app-mvflow',
  templateUrl: './mvflow.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrls: ['./mvflow.component.scss']
  
})
export class MvflowComponent implements OnInit {
  showloding = true;
  lodingImage = false;
  dataSource: MatTableDataSource<MVFlow>; 

  displayedColumns: string[] = [ 
    'action',
    'itemName',
    'um',
    'qtyStart',
    'startSum',
    'qtyIn',
    'inSum',
    'qtyOut',
    'outSum',
    'qtyEnd',
    'endSum'
  ];

  // dropdown buttons
  public status: { isopen } = { isopen: false };
  dtStart_Req_By_Config: FlatpickrOptions = this.service.CommonDateConfig(); 
  dtEnd_Req_By_Config: FlatpickrOptions = this.service.CommonDateConfig();
  @ViewChild('stDate_Req_By') stDate_Req_By; 
  @ViewChild('edDate_Req_By') edDate_Req_By;
  fromDt_type: string; toDt_type: string;
  fromDt_OpT_type: string; toDt_OpT_type: string;
  itemId : number;
  WHId: number;
  //gridFilter: Array<GridFilter> = [];
  lstSaleByWH:any=[]; 
  lstSaleByMeals:any=[]; 
  items: ItemDTO[]=[];
  whs: WHDTO[]=[];
  selection : ItemDTO;
  whsel : WHDTO;



  expandedElement: MVFlow | null;

  constructor(private dataService:DashboardService,private alertService: AlertService,public service: SystemService) {
    var cdt = new Date(); 
    cdt.setMonth(cdt.getMonth() - 1);
    this.fromDt_type = this.fromDt_OpT_type = this.service.Date_Format(cdt, 'yyyy-MM-dd');
    this.toDt_type = this.toDt_OpT_type = this.service.Date_Format(new Date(), 'yyyy-MM-dd');

   }

  ngOnInit(): void {
      this.bindMVFlow();
      // this.loadShow();
      
     
      
      this.dataService.getItemList().pipe().subscribe(data=>
        {

          if(data['status']==200)
          {
            this.items=data.data;
            
            
          }
          else
          {
            this.alertService.error(data['mesg']);
          }

          
        });

      
      this.dataService.getWhList().pipe().subscribe(data=>
        {
          if(data['status']==200)
          {
            this.whs=data.data;
            
            
          }
          else
          {
            this.alertService.error(data['mesg']);
          }
        });
      
      window.setTimeout(() => { this.SetDateOptions(); }, 10);//Date Configs


     
    }
  


  createDataSource(source:MVFlow[])
  { 
    this.dataSource=new MatTableDataSource<MVFlow>(source);
  }
  
  loadShow(){
    
  this.showloding = true;
  this.lodingImage = false;

  }

  loadHide(){
    
  this.showloding = false;
  this.lodingImage = true;
  }

  SetDateOptions() {
    //1. TicketCount_ByType
    this.stDate_Req_By.flatpickr.setDate(this.fromDt_type);
    this.stDate_Req_By.flatpickr.set("onChange", (dtarr, dt, instance) => {
        this.fromDt_type = dt;        
        
    });
    this.edDate_Req_By.flatpickr.setDate(this.toDt_type);
    this.edDate_Req_By.flatpickr.set("onChange", (dtarr, dt, instance) => {
        this.toDt_type = dt;
    });

}

toggleRow(element: MVFlow) {
  this.expandedElement = this.expandedElement === element ? null : element;
  // this.cd.detectChanges();
 
  
}

async bindMVFlow() {
  this.loadShow();
  this.createDataSource( null);
  this.dataService.getMVFlow(this.fromDt_type,this.toDt_type,this.itemId,this.WHId).pipe().subscribe(data => {
      

    
  
  if(data['status']==200)
  {
    this.createDataSource( data.data);
    
    
  }
  else
  {
    this.alertService.error(data['mesg']);
  }
  this.loadHide();});

}

changeItem(val) {
  if(val==null)
    this.itemId=0;
  else
    this.itemId=val;
}
changeWH(val) {
  if(val==null)
    this.WHId=0;
  else
    this.WHId=val;
}

}
