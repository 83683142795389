<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-block">
						<div class="card-block-title">
							<h5 class="card-title">Category</h5>
							<button id="add_btn" type="button" class="btn btn-info pull-right" (click)="open(content)" style="float: right;"> 
								Add Category
							</button>
						</div>
						<br/>
						<div class="table-responsive">
							<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-hover" width="100%">
								<thead>
									<tr>
										<th>#Sl</th>
										<th>Category</th>
										<th>Description</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of categoryList; let i = index" [attr.data-index]="i">
										<td>{{i+1}}</td>
										<td>{{item.name}}</td>
										<td>{{item.description}}</td>
										<td><span *ngIf="item.status==1" class="badge badge-success">Active</span> <span *ngIf="item.status==0" class="badge badge-danger">Inactive</span></td>
										<td><button  class="btn btn-outline-danger btn-sm" (click)="delete(this.categoryList[i].id)">x</button> <button type="button" class="btn btn-outline-primary btn-sm" (click)="edit(this.categoryList[i].id,content)"><i class="fa fa-pencil fa-lg mt-1"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #content let-c="close" let-d="dismiss" id="customer-add"> 
			<div class="modal-info" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{modalTitle}}</h4>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="form-group row">
								<label for="category" class="col-5 col-form-label">Category <span>*</span> </label>
								<div class="col-7">
									<input class="form-control" type="text" id="category" name="category" required [(ngModel)]="cat.category">
								</div>
							</div>
							<div class="form-group row">
								<label for="description" class="col-5 col-form-label">Description</label>
								<div class="col-7">
									<input class="form-control" type="text" id="description" name="description" [(ngModel)]="cat.description">
								</div>
							</div>
							<div class="form-group row">
								<label for="status" class="col-5 col-form-label">Status</label>
								<div class="col-7">
									<select class="form-control" id="status" name="status" [(ngModel)]="cat.status">
										<option value="1">Active</option>
										<option value="0">Deactive</option>
									</select>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">Close</button>
						<button type="submit" class="btn btn-outline-primary" [disabled]="!f.form.valid" (click) ="save(this.cat)">Save</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>



