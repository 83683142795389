import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @Output() editorData = new EventEmitter();

  public tools: object = {
    items: [
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink','CreateTable',
        'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|']
  };
  texteditor:string;
  constructor() { }

  ngOnInit() {
  }

  onSubmit(form: NgForm): void {
    alert(form.value.texteditor);
    this.editorData.emit(form.value.texteditor)
  }
}
