import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FlatpickrOptions } from "ng2-flatpickr";
import { GridFilter } from "../shared/common_models";
import { TranslateService } from "../shared/translate/translate.service";

@Injectable()
export class SystemService {
    public App: AppHelper;
    public CL: string = "";

    constructor(public Translator: TranslateService,private http:HttpClient) {
        
        Translator.init().then(() => { this.CL = "EN"; });
        this.App = new AppHelper(this);
    }

    CommonDateConfig(format = '') {
        let opt: FlatpickrOptions = { altInput: true, altFormat: "d M Y", dateFormat: format ? format : "Y-m-d", disableMobile: true };
        return opt;
    }
    
    get_Languages() {
        // let lst: any = [
        //     // { Key: 'Arabic', Value: 'AE' },
        //     // { Key: 'Chinese', Value: 'TW' },
        //     // { Key: 'Danish', Value: 'DK' },
        //     // { Key: 'Dutch', Value: 'NL' },
        //     { Key: 'English', Value: 'EN' },
        //     // { Key: 'French', Value: 'FR' },
        //     // { Key: 'German', Value: 'DE' },
        //     // { Key: 'Greek', Value: 'GR' },
        //     // { Key: 'Hungarian', Value: 'HU' },
        //     // { Key: 'Italian', Value: 'IT' },
        //     // { Key: 'Japanese', Value: 'JP' },
        //     // { Key: 'Polish', Value: 'PL' },
        //     // { Key: 'Portuguese', Value: 'PT' },
        //     { Key: 'Russian', Value: 'RU' },
        //     { Key: 'Armenian', Value: 'HY' },
        //     // { Key: 'Spanish', Value: 'ES' },
        //     // { Key: 'Swedish', Value: 'SE' },
        //     // { Key: 'Turkish', Value: 'TR' }
        // ];
        // console.log( lst);
        return  this.http.get('/api/get-languages');
    }
    Date_Format(input: Date | string, format: string) {
        return new DatePipe("en-us").transform(input, format)
    }


}


class AppHelper {
    constructor(public service: SystemService) { }
    public ShowLoader: boolean = false; public AppLoader: boolean = true;
    public RefreshData: EventEmitter<any> = new EventEmitter();

    public searchFilter: EventEmitter<any> = new EventEmitter();
    public refreshGrid: EventEmitter<any> = new EventEmitter();
    public showhideColumnFilter: EventEmitter<any> = new EventEmitter();
    public clearAllCheckbox: EventEmitter<any> = new EventEmitter();

    public _appData: any = {};
    GetData<T>(key: string): T {
        return (<T>this._appData[key]);
    }
    SetData(key: string, data: any) {
        this._appData[key] = data;
    }
    setCookie(cname, cvalue, date: Date | number) {
        let d = new Date();
        if (date instanceof Date) {
            d = <Date>date;
        } else {
            d.setTime(d.getTime() + (<number>date * 24 * 60 * 60 * 1000));
        }
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    checkSetting() {
        this.setCookie("Bearer", "", 0);
    }
    Clear_Local_Storage() {
        window.localStorage.clear();
    }
    set_localstorage(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    get_localstorage(key) {
        let value = localStorage.getItem(key);
        return JSON.parse(value);
    }
    get_cached_column(key, gridfilter: Array<GridFilter>) {
        let cols = this.get_localstorage(key);
        if (cols) {
            gridfilter.map(d => {
                let item = cols.find(x => x.col == d.ColumnName);
                if (item) { d.Is_Visible = item.show; }
            });
        }
    }
}