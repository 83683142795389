import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';



@Injectable()
export class CustomerService {

  constructor(private http:HttpClient) { }


  save(data){
    return this.http.post('/api/customer-save', data);
  }

  getAllCustomer(){
    return this.http.get('/api/all-customer');
  }

  getCustomer(id){
 
    return this.http.post('/api/get-customer',{id:id});
  }

  customerUpdate(data){
    return this.http.post('/api/customer-update',data);
  }

  getCustomerInfo(data){
    return this.http.post('/api/get-customer-info',{id:data});
  }

}
