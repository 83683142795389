<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
  <img src="assets/img/loading.gif">
</div>




<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
<div class="animated fadeIn" >
 <div class="card">
  <div class="card-header">
    <h3 class="card-title">{{'lblMVFlow'| translate:service.CL}}</h3>
  </div>
  <div class="card-body">
    <div class="row">   
  
        <!-- <div class="col-sm-5">
          <h3 class="card-title">{{'lblSales'| translate:service.CL}}</h3>
        </div> -->
      
        <div class="col-md-2 col-xs-12">{{'lblstartDate'| translate:service.CL}}
          <div class="table-filter summary input-group datepicker mr-2 ml-1">
              <ng2-flatpickr #stDate_Req_By [config]="dtStart_Req_By_Config" altInputClass="form-control"></ng2-flatpickr>
          </div>
        </div>
        
        <div class="col-md-2 col-xs-12"> {{'lblendDate'| translate:service.CL}}
          <div class="table-filter summary input-group datepicker mr-2 ml-1">
              <ng2-flatpickr #edDate_Req_By [config]="dtEnd_Req_By_Config" altInputClass="form-control"></ng2-flatpickr>
          </div>
        </div>

        <div class="col-md-3 col-xs-12">{{'lblItems'| translate:service.CL}}
          
            <ng-select #nameSelect [items]="items"
            [selectOnTab]="true"
            bindLabel="name"
            bindValue="id"
            labelForId="id"
            [virtualScroll]="true"
            (change)="changeItem(selection)" [(ngModel)]="selection">
          </ng-select>
          
        </div>
        <div class="col-md-3 col-xs-12">{{'lblWHs'| translate:service.CL}}
          
          <ng-select #nameSelect [items]="whs"
          [selectOnTab]="true"
          bindLabel="name"
          bindValue="id"
          labelForId="id"
          [virtualScroll]="true"    
          (change)="changeWH(whsel)" [(ngModel)]="whsel"       
          >
          </ng-select>
        
      </div>

        <div class="col-md-2 col-xs-12">  
          <!-- <div class="table-filter summary input-group datepicker mr-2 ml-1">     -->
            <button type="button" class="col-xs-1 btn  btn-success " (click)="bindMVFlow()" >Refresh</button>
          <!-- </div> -->
        </div>

       
       

        
    </div>
   
  </div>  
  </div>


  <div class="row">
    <div class="card full-height">
      <div class="col-sm-12">
        <div class="table-responsive">
          <section class="table-container mat-elevation-z8" tabindex="0">

  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="table table-bordered table-hover grid-height" matSort>


  <ng-container matColumnDef="action">
    <th class="table-column-150" mat-header-cell *matHeaderCellDef>
    </th>
    <td class="table-column-150" mat-cell *matCellDef="let so">
      <i class="fas fa-chevron-right btn" *ngIf="so != expandedElement" (click)="toggleRow(so)"></i>
      <i class="fas fa-chevron-down btn" *ngIf="so == expandedElement" (click)="toggleRow(so)"></i>
    </td>
  </ng-container>

    <!-- UserName Column -->
    <ng-container matColumnDef="itemName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblname'| translate:service.CL}}</th>
      <td mat-cell *matCellDef="let element" > {{element.itemName}} </td>
    </ng-container>
    
    <!-- UserName Column -->
    <ng-container matColumnDef="um">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblUM'| translate:service.CL}}</th>
      <td mat-cell *matCellDef="let element" > {{element.um}} </td>
    </ng-container>

     <!-- UserName Column -->
     <ng-container matColumnDef="qtyStart">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblStart'| translate:service.CL}}</th>
      <td mat-cell *matCellDef="let element" > {{element.qtyStart}} </td>
    </ng-container>


      <!-- UserName Column -->
      <ng-container matColumnDef="startSum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblStartSum'| translate:service.CL}}</th>
        <td mat-cell *matCellDef="let element" > {{element.startSum}} </td>
      </ng-container>

       <!-- UserName Column -->
       <ng-container matColumnDef="qtyIn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblqtyIn'| translate:service.CL}}</th>
        <td mat-cell *matCellDef="let element" > {{element.qtyIn}} </td>
      </ng-container>


      <!-- UserName Column -->
      <ng-container matColumnDef="inSum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblInSum'| translate:service.CL}}</th>
        <td mat-cell *matCellDef="let element" > {{element.inSum}} </td>
      </ng-container>


         <!-- UserName Column -->
         <ng-container matColumnDef="qtyOut">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblqtyOut'| translate:service.CL}}</th>
          <td mat-cell *matCellDef="let element" > {{element.qtyOut}} </td>
        </ng-container>


          <!-- UserName Column -->
          <ng-container matColumnDef="outSum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblOutSum'| translate:service.CL}}</th>
            <td mat-cell *matCellDef="let element" > {{element.outSum}} </td>
          </ng-container>


            <!-- UserName Column -->
          <ng-container matColumnDef="qtyEnd">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblqtyEnd'| translate:service.CL}}</th>
            <td mat-cell *matCellDef="let element" > {{element.qtyEnd}} </td>
          </ng-container>


          <!-- UserName Column -->
        <ng-container matColumnDef="endSum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">{{'lblendSum'| translate:service.CL}}</th>
          <td mat-cell *matCellDef="let element" > {{element.endSum}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let soObject" [attr.colspan]="displayedColumns.length">
            <ng-container *ngIf="soObject == expandedElement">
              <div class="example-element-detail"
                [@detailExpand]="soObject == expandedElement ? 'expanded' : 'collapsed'">
                <div class="inner-table mat-elevation-z8">
                  <app-mvflowdetail [mvFlow]="soObject" [stDate]="fromDt_type" [endDate]="toDt_type"></app-mvflowdetail>

                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  
  </table>
  </section>
</div>
</div>
</div>
  </div>
  </div>
</div>



