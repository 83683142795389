<!-- <div  *ngIf="message" [class.bounceInRight]="!visibleOut" [class.bounceOutRight]="visibleOut" 
class="notification alert-dismissible animated" [ngClass]="alert-success">
	{{message.text}}
	<a class="close" (click)="alertClose()">&times;</a>
</div> -->


<ng-container *ngFor="let alert of alerts">
	<div *ngIf="alert.text" class="notification alert-dismissible animated" 
	[class.bounceInRight]="!visibleOut" [class.bounceOutRight]="visibleOut" 
		  [ngClass]="alert.cssClass" (click)="alertClose(alert)">
		<i *ngIf="alert.type == 'success'" class="fa fa-check-circle icon"></i>
		<i *ngIf="alert.type == 'error'" class="fa fa-times-circle icon"></i>
		<i *ngIf="alert.type == 'info'" class="fa fa-info-circle icon"></i>
		<i *ngIf="alert.type == 'warning'" class="fa fa-exclamation-circle icon"></i>
		<div class="">
			{{alert.text}}
		</div>
		<a class="close" data-dismiss="alert">×</a>
	</div>
   </ng-container>





