import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AlertService, PurchasesHistoryService,AppService } from '../../_services/index';
import { appConfig } from '../../app.config';

class Purchases {
  id: number;
  purchase_code: string;
  company: string;
  amount: string;
  due: string;
  date: string;
}

@Component({
  selector: 'app-purchases-history',
  templateUrl: './purchases-history.component.html'
})
export class PurchasesHistoryComponent implements OnInit {
	dtOptions: DataTables.Settings = {}; //  DataTable
	dtTrigger = new Subject(); //  DataTable
	purchasesList: Purchases[] = []; // Table Data list
	purchasesHistory:any =[];
	pdf = false;
	exl = false;
	print = false;
	setting = {currency:''};
	showloding = true;
  	lodingImage = false;
	constructor(
  			public router: Router,
			private dataService:PurchasesHistoryService,
			private AppService:AppService,
			private alertService:AlertService,
			) { }

	ngOnInit() {
		this.loadShow();
		this.dataService.getAllPurchases()
			.pipe().subscribe(data => { 
				this.purchasesList = data['purchase'];
				this.dtTrigger.next(); // Data Table
				this.pdf = true;
				this.exl = true;
				this.print = true;
				this.loadHide();
			});
		let settingData = JSON.parse(localStorage.getItem('setting'));
    	this.setting = {currency:settingData.currency};
	}

	purchasesDetails(id){
		this.router.navigate(['purchases-details/' + id]);
	}

	duePayment(id){
		this.router.navigate(['purchases-due-payment/' + id]);
	}

	pdfExport(){
		window.open(appConfig.apiUrl+"/api/purchases-history-list-pdf", "_blank");
	}
	xlExport(){
		window.open(appConfig.apiUrl+"/api/purchases-history-list-excel", "_blank");
	}

	printTable() {
		var divToPrint = document.getElementById('table');
		var htmlToPrint = '' +
		'<style type="text/css">' +
		'table th, table td {' +
		'border:1px solid #000;' +
		'padding;0.5em;' +
		'}.action{display:none;}' +
		'</style>';
		htmlToPrint += divToPrint.outerHTML;
		let newWin = window.open("");
		newWin.document.write(htmlToPrint);
		newWin.print();
		newWin.close();
	    /*var printContents = document.getElementById('table').innerHTML;
	    var originalContents = document.body.innerHTML;
	    document.body.innerHTML = printContents;
		// document.body.style.marginTop="-45px";
	    window.print();
	    document.body.innerHTML = originalContents;*/
	}

	loadShow(){
	    this.showloding = true;
	    this.lodingImage = false;
	}

	loadHide(){
	    this.showloding = false;
		this.lodingImage = true;
	}
}
