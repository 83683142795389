import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingService {
    
    constructor(private http: HttpClient) { }

    getSettingData() {
        return this.http.get('/api/get-setting-data');
    }
    settingUpdate(data){
    	return this.http.post('/api/setting-data-update',data);
    }
    getDefaultLanguage() {
        
        return this.http.get('/api/get-default-language');
        
    }
    getTranslate(params: any = {}) {
        
        return this.http.get('/api/get-translate',params);
        // { params: { lang: lang ? lang : 'EN' } }
        
    }

    
}