<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
        <form method="post" enctype="multipart/form-data" class="form-horizontal" (ngSubmit)="createSales()" #f="ngForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-block"> 
                            <h5 class="card-title">Products Sale</h5>
                            <div class="form-group row">
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <div class="col-md-12">
                                            <!-- <label class="form-control-label" for="allCategory">Select Category </label> -->
                                            <select class="form-control" id="allCategory" name="allCategory"  [(ngModel)]="sales.allCategory" (ngModelChange)="selectCat($event)">
                                                <option value="" selected disabled>Select a Category</option>
                                                <option *ngFor="let item of categoryList" value="{{item.id}}">{{item.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div *ngIf="sales.allCategory !=0">
                                        <div class="form-group row">
                                            <div class="col-md-12">
                                            <label for="subCategory" class="form-control-label">Sub Category</label>
                                                <select class="form-control" id="subCategory" name="subCategory"  [(ngModel)]="sales.subCategory" (ngModelChange)="selectsubCategory($event)">
                                                    <option value="" selected>Please Select</option>
                                                    <option *ngFor="let item of subCat" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-12">
                                            <label class="form-control-label" for="allProduct">Product</label>
                                                <select class="form-control" id="allProduct" name="allProduct"  [(ngModel)]="sales.allProduct" (ngModelChange)="selectProduct($event)">
                                                    <option value="" selected disabled>Please Select</option>
                                                    <option *ngFor="let item of productList" value="{{item.id}}">{{item.name}} - ({{item.stock_quantity-item.damagedQuantity}})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="table-responsive">
                                        <table class="table table-bordered" id="add-product">
                                            <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Serial</th>
                                                  <th style="width: 30%;">Name</th>
                                                  <th>Quantity</th>
                                                  <th>Unit Price</th>
                                                  <th>Total</th>
                                                  <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let po of sales.products; let i=index" class="panel panel-default">
                                                    <td>{{i + 1}}</td>
                                                    <td><input type="text" class="form-control form-control-sm" readonly name="serial_number_{{i}}" [(ngModel)]="sales.products[i].serial_number"></td>
                                                    <td><input type="text" class="form-control form-control-sm" name="name_{{i}}" [(ngModel)]="sales.products[i].name"></td>
                                                    <td><input type="number" class="form-control form-control-sm" name="quantity_{{i}}" [(ngModel)]="sales.products[i].quantity" (ngModelChange)="itemChange()"></td>
                                                    <td><input type="number" class="form-control form-control-sm" name="selling_price_{{i}}" [(ngModel)]="sales.products[i].selling_price" (ngModelChange)="itemChange()"></td>
                                                    <td><input type="text" class="form-control form-control-sm" name="total_{{i}}"[(ngModel)]="sales.products[i].total" readonly></td>
                                                    <td><button (click)="deleteTableRow(i)" class="btn btn-danger btn-sm">x</button></td> 
                                                </tr>
                                              </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 px-3">
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Customer</legend>
                                        <div class="form-group row">
                                            <label for="invoiceCode" class="col-5 col-form-label">Invoice Code</label>
                                            <div class="col-7">
                                                 <input class="form-control" type="text" id="invoiceCode" name="invoiceCode" [(ngModel)]="sales.invoiceCode" readonly> 
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="customer" class="col-5 form-control-label">Customer <span>*</span></label>
                                            <div class="col-7">
                                                <select class="form-control" id="customer" name="customer" [(ngModel)]="sales.customer" (ngModelChange)="selectCustomer($event)" required>
                                                    <option value="" selected disabled>Please Select</option>
                                                    <option *ngFor="let item of allCustomer" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="date" class="col-5 col-form-label">Date <span>*</span> </label>
                                            <div class="col-7">
                                                <div class="input-group">
                                                    <input class="form-control" placeholder="yyyy-mm-dd" name="date" id="date" [(ngModel)]="sales.date" required ngbDatepicker #d="ngbDatepicker">
                                                    <button class="input-group-addon" (click)="d.toggle()" type="button">
                                                    <i class="fa fa-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-md-6 px-3">
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Payment</legend>
                                        <div class="form-group row">
                                            <label for="subTotal" class="col-5 col-form-label">Sub Total</label>
                                            <div class="col-7">
                                                <input type="text" class="form-control form-control-sm" name="subTotal" [(ngModel)]="sales.subTotal" readonly>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="discount" class="col-5 col-form-label">Discount</label>
                                            <div class="col-7">
                                                <input type="text" class="form-control form-control-sm" name="discount" [(ngModel)]="sales.discount" (ngModelChange)="changeGrandTotal()">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="vat" class="col-5 col-form-label">Vat</label>
                                            <div class="col-7">
                                                <input type="text" class="form-control form-control-sm" name="vat" [(ngModel)]="sales.vat" (ngModelChange)="changeGrandTotal()">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="grandTotal" class="col-5 col-form-label">Grand Total</label>
                                            <div class="col-7">
                                                <input type="text" class="form-control form-control-sm" name="grandTotal" [(ngModel)]="sales.grandTotal" readonly>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="receivedAmount" class="col-5 form-control-label">Received Amount <span>*</span></label>
                                            <div class="col-7">
                                                <input class="form-control form-control-sm" type="number" id="receivedAmount" name="receivedAmount" [(ngModel)]="sales.receivedAmount" (ngModelChange)="amountChange()" required>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="due" class="col-5 form-control-label">Due</label>
                                            <div class="col-7">
                                                <input class="form-control form-control-sm" type="text" id="due" name="due" [(ngModel)]="sales.due" readonly>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="paymentType" class="col-5 form-control-label">Payment Type <span>*</span></label>
                                            <div class="col-7">
                                                <select class="form-control" type="text" id="paymentType" name="paymentType" [(ngModel)]="sales.paymentType" required>
                                                    <option value="">Please Select</option>
                                                    <option *ngFor="let item of paymentTypeList" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button type="submit" [disabled]="!f.form.valid" class="btn btn-info btn-sm">New Sales</button>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
