import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, SubCategoryService,AppService } from '../_services/index';

import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

class subCategoryGrid {
  id: number;
  name: string;
  categoryName: string;
  description: string;
  status: string;
}

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html'
})
export class SubCategoryComponent implements OnInit {
	dtOptions: DataTables.Settings = {}; //  DataTable
	dtTrigger = new Subject(); //  DataTable
	subCategoryList: subCategoryGrid[] = []; // Table Data list
  // subCategoryAddForm: FormGroup;
	getSubCat = {
    id:'',
    category_id: '',
    name: '',
    description: '',
    status: 1
  };
  modalTitle: string; 
  constructor(
              public router: Router,
              private dataService:SubCategoryService,
              private alertService: AlertService,
              private AppService: AppService,
              private modalService: NgbModal
            ) { }
	subCat = {
    id:'',
    category:'',
    subCategory:'',
    description:'',
    status: 1
  };
	allcategory = {};
  modalReference:NgbActiveModal;
  showloding = true;
  lodingImage = false;
	ngOnInit() {
    this.allSubCategory();
    this.dataService.getCategory()
        .pipe().subscribe(data => { 
        this.allcategory = data['cat'];
    });
	}

  allSubCategory(){
    this.loadShow();
    this.dataService.getAllSubCategory()
        .pipe().subscribe(data => { 
        this.subCategoryList = data['subCat']; 
        this.dtTrigger.next(); // Data Table
        this.loadHide();
      });
  }

	save(val){
      // this.customer.id;
      this.loadShow();
  		if(val.id==''){
  		  this.dataService.save(this.subCat)
            .pipe().subscribe(data =>{
              this.loadHide();
              this.modalReference.close();
              this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('Sub Category Create successful', true);
              this.allSubCategory();
            },error =>{
              this.alertService.error(error);
              this.loadHide();
            });
  		}else{
  		  this.dataService.subCategoryUpdate(this.subCat)
            .pipe().subscribe(data =>{
              this.loadHide();
               this.modalReference.close();
              this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('Sub Category Update successful', true);
              this.allSubCategory();
            },error =>{
              this.alertService.error(error);
              this.loadHide();
            });
  		}
  	}

  edit(id,content){
    this.loadShow();
    this.dataService.getSubCategory(id)
        .pipe().subscribe(data => { this.getSubCat = data['subCat']; 
              this.subCat = {
                    id:this.getSubCat.id,
                    category:this.getSubCat.category_id,
                    subCategory:this.getSubCat.name,
                    description:this.getSubCat.description,
                    status:this.getSubCat.status
              };
              this.loadHide();
              this.modalTitle = "Edit Sub Category";
              this.modalReference = this.modalService.open(content);
            });

	}

	open(content) {
	    this.subCat.category = '';
	    this.subCat.status = 1;
      this.modalTitle = "Edit Sub Category";
	    this.modalReference = this.modalService.open(content);
	}

  delete(id){
    this.dataService.subCategoryDelete(id)
        .pipe().subscribe(data =>{
              this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('Sub Category Delete successful', true);
              this.allSubCategory();
            },error =>{
              this.alertService.error(error);
        });
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true; 
  }


}
