<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loading.gif">
</div>


<table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="table table-bordered table-hover grid-height" matSort>


   
      <!-- UserName Column -->
      <ng-container matColumnDef="operDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">Date</th>
        <td mat-cell *matCellDef="let element" > {{element.operDate  | date:'yyyy-MM-dd'}} </td>
      </ng-container>
      

       <!-- UserName Column -->
       <ng-container matColumnDef="qtyIn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">qtyIn</th>
        <td mat-cell *matCellDef="let element" > {{element.qtyIn}} </td>
      </ng-container>


        <!-- UserName Column -->
        <ng-container matColumnDef="qtyOut">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold h6">qtyOut</th>
          <td mat-cell *matCellDef="let element" > {{element.qtyOut}} </td>
        </ng-container>

     

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    </table>
