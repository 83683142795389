import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/';
import { map } from 'rxjs/operators';
// import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, CategoryService,AppService } from '../_services/index';
import $ from 'jquery/dist/jquery';
import {NgbModal, NgbActiveModal,NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

class categoryGrid {
  id: number;
  name: string;
  description: string;
  status: string;
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html'
})
export class CategoryComponent implements OnInit {

	dtOptions: DataTables.Settings = {}; //  DataTable
	dtTrigger = new Subject(); //  DataTable
	categoryList: categoryGrid[] = []; // Table Data list
  // categoryAddForm: FormGroup;
	getCat = {
    id:'',
    name: '',
    description: '',
    status: 0,
  };
  modalReference:NgbActiveModal;
  modalTitle:string;
	constructor(
              public router: Router,
              private dataService:CategoryService,
              private alertService: AlertService,
              private AppService: AppService,
              private modalService: NgbModal
             ) { }
  cat = {
    id:'',
    category:'',
    description:'',
    status: 1,
  };
  showloding = true;
  lodingImage = false;
	ngOnInit() {

    this.allCategory();
  }

  allCategory(){
    this.loadShow();
    this.dataService.getAllCategory()
        .pipe().subscribe(data => { 
          this.categoryList = data['cat']; 
          this.dtTrigger.next(); // Data Table
          this.loadHide();
        });
  }

	open(content) {

    if (this.getCat.id !=null) {
      this.getCat.id='';
    }
    this.cat.status = 1;
    this.modalTitle = "Add Category";
    this.modalReference = this.modalService.open(content);
  }

  edit(id,content){
    this.loadShow();
    this.dataService.getCategory(id)
        .pipe().subscribe(data => { 
                this.getCat = data['cat']; 
                this.cat = {
                      id:this.getCat.id,
                      category:this.getCat.name,
                      description:this.getCat.description,
                      status:this.getCat.status
                };
                this.loadHide();
                this.modalTitle = "Edit Category";
                this.modalReference = this.modalService.open(content);
        });

  }

	save(val){

		if(val.id == ''){
		  this.dataService.save(this.cat)
          .pipe().subscribe(data =>{
            this.modalReference.close();
            this.dtTrigger = new Subject(); //  DataTable
            this.alertService.success('Category Create successful', true);
            this.allCategory();
          },error =>{
            this.alertService.error(error);
          });
		}else{
		  this.dataService.categoryUpdate(this.cat)
          .pipe().subscribe(data =>{
            this.modalReference.close();
            this.dtTrigger = new Subject(); //  DataTable
            this.alertService.success('Category Update successful', true);
            this.allCategory();
          },error =>{
            this.alertService.error(error);
          });
		}
	}

  delete(id){
    if(confirm("Are you sure to delete")) {
      this.dataService.categoryDelete(id)
          .pipe().subscribe(data =>{
            if(data['status']==200){
              this.dtTrigger = new Subject(); //  DataTable
              this.alertService.success('Category Delete successful', true);
              this.allCategory();
            }else{
              this.alertService.error('Category Assigned Product');
            }
          },error =>{
            this.alertService.error(error);
          });
    }
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
    this.showloding = false;
    this.lodingImage = true;
  }
  
}
