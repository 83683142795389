import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService,AlertService } from '../_services/index';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
                private router: Router,
                private AppService: AppService,
                private alertService: AlertService,
            ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.alertService.alertClose();
        let url = state.url.split("/")[1];
        let getToken = localStorage.getItem('currentUser');
        
         if(url !='login'){
            if(getToken!=null)
            {
                this.AppService.checkLogin(getToken).pipe().subscribe(
                    data => {
                        if(data['status']==400){
                            window.localStorage.removeItem('currentUser');
                            this.router.navigate(['/login']);
                        }
                    },
                    err => {
                        window.localStorage.removeItem('currentUser');
                        this.router.navigate(['/login']); 
                    }
                );
            }
            else
            {
                window.localStorage.removeItem('currentUser');
                this.router.navigate(['/login']); 
            }

        } 
    
        if (localStorage.getItem('currentUser') !=null) {
            if(url !='login'){
                this.AppService.checkPermission(url)
                .pipe().subscribe(data => { 
                  if(data['count']==0){
                    this.router.navigate(['/dashboard']);
                  } 
                });
            }else{
                this.router.navigate(['/dashboard']);
            }
            // logged in so return true
            return true;
        }else{
            
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }

    }

}