<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
  <div class="animated fadeIn">
      <div class="row">
          <div class="col-lg-12">
              <div class="card">
                  <div class="card-block">
                    <div class="card-block-title">
                      <h5 class="card-title">Purchases History</h5>
                      <a routerLink="/purchases"><button id="add_btn" type="button" class="btn btn-info pull-right">New Purchases</button></a>
                    </div>
                    <br/>
                      <div class="table-responsive">
                        <table id="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-hover" width="100%">
                          <thead>
                              <tr>
                                <th>#Sl</th>
                                <th>Purchase Code</th>
                                <th>Supplier Name</th>
                                <th>Amount</th>
                                <th>Due</th>
                                <th>Date</th>
                                <th class="action">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let item of purchasesList; let i = index" [attr.data-index]="i">
                								<td>{{i+1}}</td>
                								<td>{{item.purchase_code}}</td>
                                <td>{{item.company}}</td>
                                <td>{{item.amount}} {{setting.currency}}</td>
                                <td>{{item.due}} {{setting.currency}}</td>
                								<td>{{item.date}}</td>
                                <td class="action"><button type="button" class="btn btn-outline-primary btn-sm" (click)="purchasesDetails(this.purchasesList[i].id)"><i class="fa fa-eye"></i></button> <button *ngIf="item.due > 0" (click)="duePayment(this.purchasesList[i].id)" class="btn btn-outline-info btn-sm"><i class="fa fa-money"></i> Due Payment</button> <span *ngIf="item.due==0" class="badge badge-success">Paid</span> <span *ngIf="item.due < 0" class="badge badge-primary">Advance</span></td>
                              </tr>
                          </tbody>
                        </table>
                    </div>
                  </div>
              </div>
          </div>
          <!--/.col-->
      </div>
  </div>
</div>
