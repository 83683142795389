<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
    <div class="animated fadeIn">
    	<div class="card">
            <div class="card-header card-header-dark">
                Invoice
                <strong>#{{invoiceDetails.invoice_code}}</strong>
                <a href="#" target="_blank" class="btn btn-sm btn-info float-right" (click)="printView()"><i class="fa fa-print"></i> Print</a>
            </div>
            <div class="card-block" id="printJS-form" style="width: 100%;">
            	
                <div class="row mb-12">
                <!-- {{invoiceDetails.company}} -->
                    <div class="col-sm-4">
                        <h6 class="mb-3">From:</h6>
                        <div>{{invoiceDetails.settingData.company_name}}</div>
                        <div>Email: {{invoiceDetails.settingData.email}}</div>
                        <div>Phone: {{invoiceDetails.settingData.phone}}</div>
                        <div>Currency: {{invoiceDetails.settingData.currency}}</div>
                    </div>
                    <!--/.col-->

                    <!--/.col-->
                    <div class="col-sm-8 d-flex justify-content-end">
        	            <div class="col-sm-4">
        	                <h6 class="mb-3">To</h6>
                            <div><strong>Invoice Code :</strong><span style="color: #1ab394;">{{invoiceDetails.invoice_code}}</span></div>
        	                <div>{{invoiceDetails.date}}</div>
                            <div>Name: {{invoiceDetails.name}}</div>
                            <div>Phone: {{invoiceDetails.phone}}</div>
        	                <div>Email: {{invoiceDetails.email}}</div>
        	            </div>
                    </div>
                    <!--/.col-->
                </div>
                <!--/.row-->
                <br/>
                <div class="table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="center">#</th>
                                <th>Product Code</th>
                                <th>Name</th>
                                <th class="center">Quantity</th>
                                <th class="right">Unit Cost</th>
                                <th class="right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of invoiceDetails.invoice_info; let i = index" [attr.data-index]="i">
                                <td class="center">{{i+1}}</td>
                                <td class="left">{{item.serial_number}}</td>
                                <td class="left">{{item.product_name}}</td>
                                <td class="center">{{item.quantity}}</td>
                                <td class="right">{{item.selling_price}}</td>
                                <td class="right">{{item.quantity * item.selling_price}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="d-flex justify-content-end">
                            <div class="col-md-2 text-right">
                                <p>
                                    <strong>Sub Total </strong> {{invoiceDetails.sub_total}}
                                </p>
                            </div>
                        </div>
                        <!-- <table class="table table-clear" style="display: initial">
                            <tbody>
                                <tr>
                                    <td class="left"><strong>Sub Total</strong></td>
                                    <td class="right">{{invoiceDetails.sub_total}}</td>
                                </tr>
                            </tbody>
                        </table> -->
                        <!-- <a href="#" class="btn btn-success"><i class="fa fa-usd"></i> Proceed to Payment</a> -->
                    </div>
                    <!--/.col-->

                </div>
                <!--/.row-->
                <br/>
                <div class="table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="center">#</th>
                                <th class="center">Payment Date</th>
                                <th class="right">Amount</th>
                                <th class="right">Payment Method</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of invoiceDetails.payment_info; let i = index" [attr.data-index]="i">
                                <td class="center">{{i+1}}</td>
                                <td class="left">{{item.payment_date}}</td>
                                <td class="center">{{item.payment_amount}}</td>
                                <td class="right">{{ ((item.payment_type==1) ? 'cash' : (item.payment_type==2) ? 'check' : 'card')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-lg-12 d-flex justify-content-end">
                        <div class="col-lg-4">
                            <table class="table table-clear">
                                <tbody>
                                    <tr>
                                        <td class="left">VAT %</td>
                                        <td class="right">{{invoiceDetails.vat}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">Discount %</td>
                                        <td class="right">{{invoiceDetails.discount}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">Grand Total</td>
                                        <td class="right">{{invoiceDetails.grand_total}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">Total Paid</td>
                                        <td class="right">{{invoiceDetails.total_paid}}</td>
                                    </tr>
                                    <tr>
                                        <td class="left">Payment Due</td>
                                        <td class="right">{{invoiceDetails.due}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <!-- <a href="#" class="btn btn-success"><i class="fa fa-usd"></i> Proceed to Payment</a> -->
                    </div>
                    <!--/.col-->

                </div>
                <!--/.row-->
            </div>
        </div>
    </div>
</div>