import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
// import { FormGroup, FormBuilder,FormArray, Validators, FormControl,NgModel,FormsModule  } from '@angular/forms';
import { AlertService, SalesTakePaymentService,AppService} from '../../_services/index';
import {NgbDateStruct,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sales-take-payment',
  templateUrl: './sales-take-payment.component.html'
})
export class SalesTakePaymentComponent implements OnInit {
	// salestakePaymentForm:FormGroup;

	payment:any;
	bsValue: Date = new Date();
	paymentTypeList = [{id:1,name:'cash'},{id:2,name:'check'},{id:3,name:'card'}];

	UrlParams:any;
	purchase:any;
	purchaseDetails:any = {
		invoice_id:'',
		customer_id:'',
		purchase_code:'',
		invoice_info:'',
		date:'',
		due:'',
	    total_price:'',
	    total_paid:''
	};
	showloding = true;
  	lodingImage = false;
	constructor(
  			public router: Router,
  			public activateRouter: ActivatedRoute,
			private dataService:SalesTakePaymentService,
			private alertService: AlertService,
			private AppService:AppService,
			private parserFormatter: NgbDateParserFormatter
		) { }

  	ngOnInit() {

  		this.activateRouter.params.subscribe(params => {
	      this.UrlParams = params['id'];
	    });

		this.loadShow();
	    this.dataService.getInvoiceDetails(this.UrlParams)
      		.pipe().subscribe(data => { 
				let invoice = data['invoice'];
				this.purchaseDetails = {
					invoice_id:invoice.invoice_id,
					customer_id:invoice.customer_id,
					invoice_code:invoice.invoice_code,
					date:invoice.date,
					due:invoice.due,
					discount:invoice.discount,
					vat:invoice.vat,
					grand_total:invoice.grand_total,
					total_paid:invoice.total_paid,
					invoice_info:invoice.invoice_info
				};

				let d = new Date();
				let formatDate = moment(d).format('YYYY-MM-DD');
				let dateArry = formatDate.split('-');
				let setDate = {year:  Number(dateArry[0]), month: Number(dateArry[1]), day: Number(dateArry[2])};
				this.payment = {
					invoice_id:this.purchaseDetails.invoice_id,
					customer_id:this.purchaseDetails.customer_id,
					date:setDate,
					receivedAmount:'',
					paymentType:''
				};
				this.loadHide();
      	});

  	}


    onChangeDate(event){
		let setDate = moment(event).format('YYYY-MM-DD');
		this.payment = {date :setDate};
	}

	takePayment(){
		let date = this.parserFormatter.format(this.payment.date);
		this.payment.date=date;
		this.dataService.takePayment(this.payment)
          	.pipe().subscribe(data =>{
              this.alertService.success('Sales Payment successful', true);
              this.router.navigate(['sales-invoice-details/' + data['invoice_id']]);
          });
	}

	loadShow(){
		this.showloding = true;
		this.lodingImage = false;
	}

	loadHide(){
		this.showloding = false;
		this.lodingImage = true;
	}
}
