import { Injectable } from '@angular/core';
// import {  Http,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class AppService {

  constructor(private http:HttpClient) { }

  checkLogin(token){
    let bodyString = JSON.parse(token);
    //let bodyString = token; // Stringify payload
    return  this.http.post('/api/get-user',bodyString);
    
  }

  profileUpdate(data)
  {
  	return this.http.post('/api/user-profileUpdate',data);
  }

  updatePassword(data)
  {
  	return this.http.post('/api/user-passwordUpdate',data);
  }

  getMenu() {
    return this.http.get('/api/get-all-menu');
  }

  checkPermission(val){
    return this.http.post('/api/check-user-permission',{menu:val});
  }
}
