import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

  constructor(private http:HttpClient) { }

  save(data){
  	return this.http.post('/api/user-create',data);

  }

  getAlluser(){
    return  this.http.get('/api/all-user');
  }

  getUser(id){
    return this.http.post('/api/get-user-data',{id:id});
  }

  userUpdate(data){
    return this.http.post('/api/user-update',data);
  }

  userDelete(id){
    return this.http.post('/api/user-delete',{id:id});
  }
  
}
