import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchaseReportService {
    
    constructor(private http: HttpClient) { }

    getReportData(data) {
        return this.http.post('/api/get-purchase-report-data',data);
    }    
}