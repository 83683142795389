import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
// import { FormGroup, FormBuilder,FormArray, Validators, FormControl,NgModel,FormsModule  } from '@angular/forms';
import { AlertService, ReportService,AppService } from '../_services/index';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { appConfig } from '../app.config';
import {NgbDateStruct,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

class Report {
  id: number;
  invoice_code:string;
  name: string;
  date: string;
  amount: string;
  due: string;
  payment_type: string;
}

@Component({
  selector: 'app-report',
  templateUrl: './sales-report.component.html'
})
export class SalesReportComponent implements OnInit {
	@ViewChild(DataTableDirective , {static: false})
  	dtElement: DataTableDirective;

	dtOptions: DataTables.Settings = {}; //  DataTable
  	dtTrigger = new Subject(); //  DataTable

  	reportList: Report[] = []; // Table Data list
	// reportSearchForm: FormGroup;
	constructor(
				public router: Router,
				private dataService:ReportService,
				private alertService: AlertService,
				private AppService: AppService,
				private parserFormatter: NgbDateParserFormatter
				) { }
	// report:any;
	d = new Date();
	formDate = moment(this.d).subtract('days', 30).format('YYYY-MM-DD');
	formDateArry = this.formDate.split('-');
	toDate = moment(this.d).format('YYYY-MM-DD');
	toDateArry = this.toDate.split('-');
	setDateFromDate = {year:  Number(this.formDateArry[0]), month: Number(this.formDateArry[1]), day: Number(this.formDateArry[2])};
	setDateToDate = {year:  Number(this.toDateArry[0]), month: Number(this.toDateArry[1]), day: Number(this.toDateArry[2])};
	report:any;
	setting = {currency:''};
	pdf = false;
	exl = false;
	showloding = true;
  	lodingImage = false;
	ngOnInit() {
		
		this.report = {
			fromdate:this.setDateFromDate,
			todate:this.setDateToDate
		};
		
		this.getReport();
		let settingData = JSON.parse(localStorage.getItem('setting'));
      	this.setting = {currency:settingData.currency};
	}

	getReport(){
		this.loadShow();
		let fromdate = this.parserFormatter.format(this.report.fromdate);
		let todate = this.parserFormatter.format(this.report.todate);
		let setReport = {fromdate:fromdate,todate:todate};
		// this.report.todate=todate;

		this.dataService.getReportData(setReport)
            .pipe().subscribe(data =>{
            	this.reportList = data['report'];
            	// this.dtTrigger = new Subject(); //  DataTable
            	this.dtTrigger.next(); // Data Table
            	this.pdf = true;
				this.exl = true;
				this.loadHide();
            },error =>{
			  this.alertService.error(error);
			  this.loadHide();
            });
	}

	searchReport(){
	    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
	      // Destroy the table first
	      dtInstance.destroy();
	      this.getReport();
	    });
  	}

  	pdfExport(){
  		let fromdate = this.parserFormatter.format(this.report.fromdate);
		let todate = this.parserFormatter.format(this.report.todate);
    	window.open(appConfig.apiUrl+"/api/sales-report-pdf?fromdate="+fromdate+"&todate="+todate, "_blank");
  	}
  	xlExport(){
  		let fromdate = this.parserFormatter.format(this.report.fromdate);
		let todate = this.parserFormatter.format(this.report.todate);
    	window.open(appConfig.apiUrl+"/api/sales-report-excel?fromdate="+fromdate+"&todate="+todate, "_blank");
  	}
	
	loadShow(){
	    this.showloding = true;
	    this.lodingImage = false;
	}

	loadHide(){
	    this.showloding = false;
		this.lodingImage = true;
	}
}
