<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-7" style="margin: auto;">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title">Setting</h5>
						<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
							<div class="form-group row">
								<label for="company_name" class="col-5 col-form-label">Company Name <span>*</span> </label>
								<div class="col-7">
									<input class="form-control" type="text" id="company_name" name="company_name" required [(ngModel)]="setting.company_name">
								</div>
							</div>
							<div class="form-group row">
								<label for="phone" class="col-5 col-form-label">Phone <span>*</span></label>
								<div class="col-7">
									<input class="form-control" type="text" id="phone" name="phone" required [(ngModel)]="setting.phone">
								</div>
							</div>
							<div class="form-group row">
								<label for="email" class="col-5 col-form-label">Email <span>*</span></label>
								<div class="col-7">
									<input class="form-control" type="text" id="email" name="email" required [(ngModel)]="setting.email">
								</div>
							</div>
							<div class="form-group row">
								<label for="address" class="col-5 col-form-label">Address <span>*</span></label>
								<div class="col-7">
									<input class="form-control" type="text" id="address" name="address" required [(ngModel)]="setting.address">
								</div>
							</div>
							<div class="form-group row">
								<label for="currency" class="col-5 col-form-label">Currency <span>*</span></label>
								<div class="col-7">
									<input class="form-control" type="text" id="currency" name="currency" required [(ngModel)]="setting.currency">
								</div>
							</div>
							<div class="form-group row">
			                    <label class="col-md-5 form-control-label">image</label>
			                    <div class="col-md-7">
			                    	<p><img style="height: 150px;width: 250px;" class="img-thumbnail" *ngIf="setting.image !=''" [src]="'data:image/jpg;base64,'+setting.image" /></p>
			                    	<div class="input-group">
			                        	<span class="input-group-btn">
											<label class="btn btn-primary">
												<samp>Browse</samp>...
										  		<input type="file" accept="{{fileType}}" name="file" style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple" >
											</label>
			                            </span>
			                            <input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText"  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
			                        </div>
			                    </div>
			                </div>
							<button type="submit" class="btn btn-info" [disabled]="!f.form.valid" (click) ="Update()">Update</button>
						</form>
					</div>
				</div>
			</div>
			<!--/.col-->
		</div>
	</div>
</div>


