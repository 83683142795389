<div class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container">

        <div class="row">
          
          <div class="col-md-8 col-lg-6 mx-auto">
            <div class="card-group">
              <div class="card p-4">
                <div class="card-body">
                  <alert></alert>
                  
                  <form method="post" (ngSubmit)="f.form.valid && onLoggedin()" #f="ngForm" novalidate>
                    <div id="brand-logo" *ngIf="setting.image">
                      <img [src]="'data:image/jpg;base64,'+setting.image">
                    </div>

                    <!--  <h1>Login</h1> -->
                     <!-- <p class="text-muted">Sign In to your account</p> -->
                    <!-- <span class="help-block alert-text" *ngIf="show">{{textMesg}}</span> -->

                    <div class="input-group mb-3" [ngClass]="{'has-error-login': f.submitted && model.username==undefined || model.username==''}">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" class="form-control" placeholder="{{'lblUsername' | translate:service.CL}}" autocomplete="username" name="username" [(ngModel)]="model.username" #username="ngModel">
                    </div>

                    <div class="input-group mb-4" [ngClass]="{ 'has-error-login': f.submitted && model.password==undefined || model.password=='' }">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>
                      <input type="password" class="form-control" placeholder="{{'lblPassword' | translate:service.CL}}" autocomplete="current-password" name="password" [(ngModel)]="model.password">
                    </div>

                    <div class="form-group mb-4" [ngClass]="{'has-error-login': f.submitted && SelectedLang==undefined}">
                      <div class="dropdown">
                        <button type="button" class="btn btn-lang input-group-text" id="langDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img *ngIf="SelectedLang" class="img-flag" src="../../assets/images/flag/{{SelectedLang?.value}}.png" /> {{SelectedLang?.key}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="langDropdown" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 34px, 0px);">
                          <ng-container *ngFor="let item of LanguageList">
                            <a class="dropdown-item" [class.active]="SelectedLang?.value == item.value" (click)="ChangeLang(item)">
                              <img src="../../assets/images/flag/{{item.value}}.png" />
                              {{item.key| translate:service.CL}}
                            </a>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">

                        <!-- <button type="submit" class="btn btn-primary px-4">Login</button> -->
                        <!-- || model.username==undefined || model.username=='' || model.password==undefined || model.password=='' -->

                        <button [disabled]="loading " class="btn btn-primary px-4 btn-lg font-weight-medium" type="submit">
                          <i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i>
                          {{'lblLogin'| translate:service.CL}}
                        </button>

                      </div>
                      <div class="col-6 text-right">
                        <button type="button" class="btn btn-link px-0">{{'lblForgotPassword' | translate:service.CL}}</button>
                      </div>

                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    
  </div>
