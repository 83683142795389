import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
// import { FormGroup, FormBuilder,FormArray, Validators, FormControl,NgModel,FormsModule  } from '@angular/forms';
import { AlertService, PurchasesDuePaymentService,AppService } from '../../_services/index';
import {NgbDateStruct,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-purchases-due-payment',
  templateUrl: './purchases-due-payment.component.html'
})
export class PurchasesDuePaymentComponent implements OnInit {
	// purchasesDuPaymentForm:FormGroup;
	payment:any;

	momentValue:any;
	paymentTypeList = [{id:1,name:'cash'},{id:2,name:'check'},{id:3,name:'card'}];

	UrlParams:any;
	purchase:any;
	
	purchaseDetails:any = {
		purchase_id:'',
		supplier_id:'',
		purchase_code:'',
		productInfo:'',
		date:'',
		due:'',
	    total_price:'',
	    totalAmount:''
	};
	showloding = true;
  	lodingImage = false;
	constructor(
  			public router: Router,
  			public activateRouter: ActivatedRoute,
			private dataService:PurchasesDuePaymentService,
			private alertService: AlertService,
			private AppService: AppService,
			private parserFormatter: NgbDateParserFormatter
		) { }

  	ngOnInit() {

  		this.activateRouter.params.subscribe(params => {
	      this.UrlParams = params['id'];
	    });

		this.loadShow();
	    this.dataService.getInvoiceDetails(this.UrlParams)
      		.pipe().subscribe(data => { 
				let purchase = data['purchase'];
				this.purchaseDetails = {
					purchase_id:purchase.purchase_id,
					supplier_id:purchase.supplier_id,
					purchase_code:purchase.purchase_code,
					date:purchase.date,
					due:purchase.due,
					total_price:purchase.total_price,
					totalAmount:purchase.totalAmount,
					productInfo:purchase.product_info
				};

				let d = new Date();
				let formatDate = moment(d).format('YYYY-MM-DD');
				let dateArry = formatDate.split('-');
				let setDate = {year:  Number(dateArry[0]), month: Number(dateArry[1]), day: Number(dateArry[2])};

				this.payment = {
					purchase_id:this.purchaseDetails.purchase_id,
					supplier_id:this.purchaseDetails.supplier_id,
					date:setDate,
					receivedAmount:'',
					paymentType:''
				};
				this.loadHide();
      	});

  	}


	paymentUpdate(){
		this.loadShow();
		let date = this.parserFormatter.format(this.payment.date);
		this.payment.date=date;
		this.dataService.paymentUpdate(this.payment)
          	.pipe().subscribe(data =>{
				  this.loadHide();
              this.alertService.success('Purchases Payment successful', true);
              this.router.navigate(['purchases-details/' + data['purchase_id']]);
          });
	}

	loadShow(){
	    this.showloding = true;
	    this.lodingImage = false;
	}

	loadHide(){
	    this.showloding = false;
		this.lodingImage = true;
	}
}
