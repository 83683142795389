import { Component, OnInit,Input,OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder,FormArray, Validators, FormControl,NgModel,FormsModule  } from '@angular/forms';
import { AlertService, SalesService,AppService } from '../_services/index';
import {NgbDateStruct,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

interface TestObject {
  id:number;
  name:string;
}


@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html'
})
export class SalesComponent implements OnInit {
	// salesAddForm: FormGroup;
	sales:any;
	private didPaypalScriptLoad: boolean = false;
	constructor(
				public router: Router,
				private _fb: FormBuilder,
				private alertService: AlertService,
				private dataService:SalesService,
				private AppService:AppService,
				private parserFormatter: NgbDateParserFormatter
			) { }
	allCustomer:any[] = [];
	customerDiscount:any;
	categoryList:any[] = [];
	subCat:any[] = [];
	// subCat = {};
	productList:any[]=[];
	objArray:TestObject[];
  	bsValue: Date = new Date();
	paymentTypeList = [{id:1,name:'cash'},{id:2,name:'check'},{id:3,name:'card'}];
	showloding = true;
  	lodingImage = false;
	ngOnInit() {

		let d = new Date();
     	let formatDate = moment(d).format('YYYY-MM-DD');
     	let randomnumber = moment(d).format('YYMMDDHHmmss');

		 let dateArry = formatDate.split('-');
		let setDate = {year:  Number(dateArry[0]), month: Number(dateArry[1]), day: Number(dateArry[2])};
		// usually you'd grab this from a backend API
		this.sales = {
			invoiceCode:randomnumber,
			customer: "",
			date: setDate,
			allCategory: "",
			subCategory: "",
			allProduct: "",
			subTotal: "",
			discount: "0",
			vat: "0",
			grandTotal:"",
			receivedAmount:"",
			due:"",
			paymentType:"",
			products: []
		}
		
		this.category();
		this.customer();
		
	}
	category(){
		this.loadShow();
		this.dataService.getAllCategory()
			.pipe().subscribe(data => {
				this.categoryList = data['cat'];
				this.loadHide();
			});
	}
	categoryByProduct(key,val){
		this.loadShow();
		this.dataService.getCategoryByProduct(key,val)
			.pipe().subscribe(data => { 
				this.productList = data["product"];
				this.sales.allProduct='';
				this.loadHide();
			});
	}

	customer(){
		this.dataService.getAllCustomer()
			.pipe().subscribe(data => { 
    				this.allCustomer = data['customer'];
                });
	}

	selectCustomer(event){
		if(event > 0){
			this.loadShow();
			this.dataService.getCustomerDiscount(event)
				.pipe().subscribe(data => { 
					this.customerDiscount = data['customer'];
					if(this.customerDiscount.discount_percentage){
						this.sales.discount = this.customerDiscount.discount_percentage;
					}
					this.loadHide();
                });
		}
	}

	selectCat(id){
		if(id > 0){
			this.loadShow();
			this.dataService.getSubCategory(id)
                .pipe().subscribe(data => { 
                	this.subCat = data["subCat"];
                	if(this.subCat.length==0){
                  		this.sales.subCategory = '';
					}
					this.loadHide();
           		 });
			this.categoryByProduct(1,id); //
		}
  }
	selectsubCategory(event){
		if(event > 0){
			this.categoryByProduct(2,event);
		}
	}

	selectProduct(event){
		if(event > 0){
			this.loadShow();
			this.dataService.getProductInfo(event)
			.pipe().subscribe(data => { 
				let item = data["product"];
				var product = {id:item.id,serial_number: item.serial_number, name: item.name, quantity: 1,selling_price:item.selling_price,total:null};
				// add pay off to both the model and to form controls because I don't think Angular has any way to do this automagically yet
				this.sales.products.push(product);
				// const control = <FormArray>this.salesAddForm.controls['products'];
				// control.push(this.createFormGroupArray(product));
				this.loadHide();
				this.itemChange();
			});
            	
		}
	}

	itemChange(index=''){
		let totalAmount=0;
		for (let key in this.sales.products) {
			if(index){
				this.sales.products[index].total = (this.sales.products[index].selling_price * this.sales.products[index].quantity);
			}else{
				this.sales.products[key].total = (this.sales.products[key].selling_price * this.sales.products[key].quantity);
			}
			// keys.push({key: key, value: this.sales.products[key]});
			var num = Number(this.sales.products[key].total);
			totalAmount += num;
		}
		setTimeout(() => {
            this.sales.subTotal = totalAmount;
        });
        this.changeGrandTotal();
	}

	changeGrandTotal(){
		setTimeout(() => {
			let subTotal = this.sales.subTotal;
			let discount = this.sales.discount;
			let vat = this.sales.vat;
			subTotal = subTotal-(subTotal/100*discount);
			let total = subTotal +(subTotal/100*vat);
			this.sales.grandTotal = total.toFixed(2);
			this.amountChange();
		});
	}

	amountChange(){
		setTimeout(() => {
             let totalDue = this.sales.grandTotal - this.sales.receivedAmount;
             this.sales.due =  totalDue.toFixed(2);
        });
	}

	/* createFormGroupArray(product) {
		return new FormGroup({
			serial_number: new FormControl(product.serial_number),
			name: new FormControl(product.name),
			quantity: new FormControl(product.quantity),
			selling_price: new FormControl(product.selling_price),
			total: new FormControl(product.total)
		});
	} */
	
	deleteTableRow(index:number) {
		// delete payoff from both the model and the FormArray
		this.sales.products.splice(index, 1);
		// const control = <FormArray>this.salesAddForm.controls['products'];
        // control.removeAt(index);
        this.itemChange();
        this.changeGrandTotal()
	}


	createSales(){
		let date = this.parserFormatter.format(this.sales.date);
		this.sales.date=date;
		this.loadShow();
		this.dataService.createSales(this.sales)
            .pipe().subscribe(data =>{
			  this.loadHide();
              this.alertService.success('Invoice Create successful', true);
              this.router.navigate(['sales-invoice-details/' + data["sales_id"]]);
            },error =>{
              this.alertService.error(error);
            });
	}

	loadShow(){
		this.showloding = true;
		this.lodingImage = false;
	}

	loadHide(){
		this.showloding = false;
		this.lodingImage = true;
	}
}
