import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { UserComponent } from './user/user.component';
import { UserRoleComponent } from './user/user-role/user-role.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { ProductComponent } from './product/product.component';
import { DamagedProductComponent } from './damaged-product/damaged-product.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { PurchasesHistoryComponent } from './purchases/purchases-history/purchases-history.component';
import { PurchasesDetailsComponent } from './purchases/purchases-details/purchases-details.component';
import { PurchasesDuePaymentComponent } from './purchases/purchases-due-payment/purchases-due-payment.component';
import { SalesComponent } from './sales/sales.component';
import { SalesHistoryComponent } from './sales/sales-history/sales-history.component';
import { InvoiceDetailsComponent } from './sales/invoice-details/invoice-details.component';
import { SalesTakePaymentComponent } from './sales/sales-take-payment/sales-take-payment.component';
import { SettingComponent } from './setting/setting.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { SupplierComponent}  from './supplier/supplier.component';
import { SalesReportComponent}  from './report/sales-report.component';
import { PurchasesReportComponent }   from './report/purchases-report/purchases-report.component';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/index';

import { NotificationComponent } from './notification/notification.component';
import { MvflowComponent } from './report/mvflow/mvflow.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'Dashboard'
    },
    children: [
      {  path: 'dashboard',component:DashboardComponent, canActivate: [AuthGuard],data: {title: 'Home'}},
      {  path: 'reports/mvflow', component: MvflowComponent, canActivate: [AuthGuard],data: {title: 'Items Flow Report'}},
      {  path: 'customer', component: CustomerComponent, canActivate: [AuthGuard],data: {title: 'Manage Customer'}},
      {  path: 'user', component: UserComponent, canActivate: [AuthGuard],data: {title: 'Manage User'}},
      {  path: 'user-role/:id', component: UserRoleComponent, canActivate: [AuthGuard],data: {title: 'Manage User Role'}},
      {  path: 'category', component: CategoryComponent, canActivate: [AuthGuard],data: {title: 'Manage Category'}},
      {  path: 'subcategory', component: SubCategoryComponent, canActivate: [AuthGuard],data: {title: 'Manage SubCategory'}},
      {  path: 'product', component: ProductComponent, canActivate: [AuthGuard],data: {title: 'Manage Product'}},
      {  path: 'damaged-product', component: DamagedProductComponent, canActivate: [AuthGuard],data: {title: 'Manage Damaged Product'}},
      {  path: 'purchases', component: PurchasesComponent, canActivate: [AuthGuard],data: {title: 'Manage Purchases'}},
      {  path: 'purchases-history',component: PurchasesHistoryComponent, canActivate: [AuthGuard],data: {title: 'Purchases History'}},
      {  path: 'purchases-details/:id',component: PurchasesDetailsComponent, canActivate: [AuthGuard],data: {title: 'Purchases Details'}},
      {  path: 'purchases-due-payment/:id',component: PurchasesDuePaymentComponent, canActivate: [AuthGuard],data: {title: 'Purchases Due Payment'}},
      {  path: 'sales', component: SalesComponent, canActivate: [AuthGuard],data: {title: 'Manage Sales'}},
      {  path: 'sales-history', component: SalesHistoryComponent, canActivate: [AuthGuard],data: {title: 'Sales History'}},
      {  path: 'sales-invoice-details/:id', component: InvoiceDetailsComponent, canActivate: [AuthGuard],data: {title: 'Invoice Details'}},
      {  path: 'sales-take-payment/:id', component: SalesTakePaymentComponent, canActivate: [AuthGuard],data: {title: 'Sales TakePayment'}},
      {  path: 'setting', component: SettingComponent, canActivate: [AuthGuard],data: {title: 'Manage Setting'}},
      {  path: 'profile-settings', component: ProfileSettingsComponent, canActivate: [AuthGuard],data: {title: 'Manage Profile Settings'}},
      {  path: 'supplier', component: SupplierComponent, canActivate: [AuthGuard],data: {title: 'Manage Supplier'}},
      {  path: 'sales-report', component: SalesReportComponent, canActivate: [AuthGuard],data: {title: 'Sales Report'}},
      {  path: 'purchases-report', component: PurchasesReportComponent, canActivate: [AuthGuard],data: {title: 'Purchases Report'}},
      {  path: 'notification', component: NotificationComponent, canActivate: [AuthGuard],data: {title: 'Notification'}},
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'login'
    }
  },
  // {
  //   path:'**',
  //   redirectTo: 'dashboard'
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
  /* imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ] */
})
export class AppRoutingModule {}
