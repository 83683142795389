<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
  <div class="animated fadeIn">
      <div class="row">
          <div class="col-lg-12">
            <div class="card">
                <div class="card-block">
                  <div class="card-block-title">
                    <h5 class="card-title">Supplier</h5>
                    <button id="add_btn" type="button" class="btn btn-info" (click)="open(content)" style="float: right;"> Add Supplier</button>
                  </div>
                    <p><button type="button" *ngIf="pdf" class="btn btn-secondary btn-sm" title="PDF" (click)="pdfExport()"><i class="fa fa-file-pdf-o"></i> PDF</button> <button type="button" *ngIf="exl" title="XL" class="btn btn-secondary btn-sm" (click)="xlExport()"><i class="fa fa-file-excel-o"></i> Excel</button></p>
                    <div class="table-responsive">
                      <table id="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-hover" width="100%">
                        <thead>
                            <tr>
                                <th>#Sl</th>
                                <th>Company</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th class="action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let item of supplierList; let i = index" [attr.data-index]="i">
                                <td>{{i+1}}</td>
                                <td>{{item.company}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.phone}}</td>
                                <td><span *ngIf="item.status==1" class="badge badge-success">Active</span> <span *ngIf="item.status==0" class="badge badge-danger">Inactive</span></td>
                                <td class="action"><button type="button" class="btn btn-info btn-sm" (click)="openSupplierInfoMdal(this.supplierList[i].id,supplierinfo)"><i class="fa fa-user"></i></button> <button type="button" class="btn btn-outline-primary btn-sm" (click)="edit(this.supplierList[i].id,content)"><i class="fa fa-pencil fa-lg mt-1"></i></button></td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
          </div>
          <!--/.col-->
      </div>
  </div>
  <!-- class="modal fade" -->
  <ng-template #content let-c="close" let-d="dismiss" id="supplier-add"> 
      <div class="modal-info" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">{{modalTitle}}</h4>
                  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div class="modal-body">
                  <form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
                  	<div class="form-group row">
                      <label for="company" class="col-5 col-form-label">Company Name <span>*</span></label>
                      <div class="col-7">
                        <input class="form-control" type="text" id="company" name="company" required [(ngModel)]="supplier.company">                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-5 col-form-label">Name <span>*</span></label>
                      <div class="col-7">
                        <input class="form-control" type="text" id="name" name="name" required [(ngModel)]="supplier.name">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="email" class="col-5 col-form-label">Email</label>
                      <div class="col-7">
                        <input class="form-control" type="email" id="email" name="email"  [(ngModel)]="supplier.email">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="phone" class="col-5 col-form-label">Phone <span>*</span></label>
                      <div class="col-7">
                        <input class="form-control" type="text" id="phone" name="phone" required [(ngModel)]="supplier.phone">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="status" class="col-5 col-form-label">Status <span>*</span></label>
                      <div class="col-7">
                        <select class="form-control" id="status" name="status" [(ngModel)]="supplier.status">
                          <option value="1">Active</option>
                          <option value="0">Deactive</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-5 form-control-label">image</label>
                      <div class="col-md-7">
                        <img style="height: 100px;width: 100px;" class="img-thumbnail" *ngIf="getSupplier.image !=''" [src]="'data:image/jpg;base64,'+getSupplier.image" />
                        <div class="input-group">
                            <span class="input-group-btn">
                                    <label class="btn btn-primary">
                                      <samp>Browse</samp>...
                                        <input type="file" accept="{{fileType}}" style="display: none;" (change)="fileChange($event)" [attr.multiple] ="allowMultiple" >
                                    </label>
                                </span>
                            <input type="text" class="form-control" [value]="DisplayedText" disabled="" [class.ng-invalid-right]="required && !DisplayedText"  [class.ng-valid-right]="required && DisplayedText" style="height: 35px;">
                        </div>
                      </div>
                    </div>
                  </form>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">Close</button>
                  <button type="submit" class="btn btn-outline-primary" [disabled]="!f.form.valid" (click)="save(this.supplier)">Save</button>
              </div>
          </div>
      </div>
  </ng-template>
  <ng-template #supplierinfo let-c="close" let-d="dismiss" id="supplierinfo">
      <div class="modal-info" role="document" >
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Supplier Information</h4>
                  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="row mb-4">
                    <div class="card-block">
                      <div class="col-sm-6">
                          <h6 class="mb-3">Basic Information:</h6>
                          <div>{{suplierInfo.company}}</div>
                          <div>{{suplierInfo.email}}</div>
                          <div>{{suplierInfo.phone}}</div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <img style="height: 150px;width: 150px;" class="img-thumbnail" *ngIf="suplierInfo.image !=''" [src]="'data:image/jpg;base64,'+suplierInfo.image" />
                    </div>
                      <div class="col-sm-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                              <thead>
                                  <tr>
                                      <th class="center">#</th>
                                      <th>Purchase Code</th>
                                      <th>Amount</th>
                                      <th class="right">Date</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let item of suplierInfo.purchaseInfo; let i = index" [attr.data-index]="i">
                                      <td class="center">{{i+1}}</td>
                                      <td class="right"><a (click)="purchaseInvoiceDetails(this.suplierInfo.purchaseInfo[i].id)">{{item.purchase_code}}</a></td>
                                      <td class="right">{{((item.amount==null) ? '0.00':item.amount)}} {{setting.currency}}</td>
                                      <td class="center">{{item.date}}</td>
                                  </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">Close</button>
              </div>
          </div>
      </div>
  </ng-template>
</div>



