import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../_services/index';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})




export class AlertComponent {
    // message: any;
    visibleOut: boolean = false;
    alerts: any[] = []; 
    msgClass: string = "";
    timeout: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        //this.alertService.getMessage().subscribe(message => { this.message = message;this.visibleOut = false; });
        

        this.alertService.getMessage().subscribe((alert) => {
            window.clearTimeout(this.timeout);
            if (!alert || !alert.text) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
            // add alert to array            
            this.alerts = [];//for only display one message
            this.visibleOut = false;
            this.alerts.push(alert);
            this.timeout = window.setTimeout(() => { this.alertClose(alert); }, 5000);
            
        });

    }

    alertClose(alert:any){
    	// this.message = false;
        this.visibleOut = true;
        setTimeout(() => {
            this.alerts = this.alerts.filter(x => x !== alert);
        }, 250);
    }
}