<div id="loding" [ngClass]="{ 'loding-hide': lodingImage }">
    <img src="assets/img/loding.gif">
</div>
<div id="ui-view" [ngClass]="{ 'loding-ui-view': showloding }">
	<div class="animated fadeIn">
		<form method="post" enctype="multipart/form-data" class="form-horizontal" #f="ngForm">
			<div class="row justify-content-end">
				<div class="col-lg-12">
					<div class="card">
					    <div class="card-header card-header-dark">
					        Invoice<strong>#{{purchaseDetails.purchase_code}}</strong>
					    </div>
					    <div class="card-block">
					        <div class="table-responsive">
					            <table class="table table-bordered">
					                <thead>
					                    <tr>
					                        <th class="center">#</th>
					                        <th>Name</th>
					                        <th class="center">Quantity</th>
					                        <th class="right">Unit Cost</th>
					                        <th class="right">Total</th>
					                    </tr>
					                </thead>
					                <tbody>
					                    <tr *ngFor="let item of purchaseDetails.productInfo; let i = index" [attr.data-index]="i">
					                        <td class="center">{{i+1}}</td>
					                        <td class="left">{{item.product_name}}</td>
					                        <td class="center">{{item.quantity}}</td>
					                        <td class="right">{{item.purchase_price}}</td>
					                        <td class="right">{{item.quantity * item.purchase_price}}</td>
					                    </tr>
					                </tbody>
					            </table>
					        </div>
					        <div class="row">
					        	<div class="col-lg-6 px-3" style="margin-top: 10px">
					                <table class="table table-condensed">
					                    <tbody>
					                    	<tr>
					                            <td class="left"><strong>Sub Total</strong></td>
					                            <td class="right">{{purchaseDetails.total_price}}</td>
					                        </tr>
					                        <tr>
					                            <td class="left"><strong>Total Paid</strong></td>
					                            <td class="right">{{purchaseDetails.totalAmount}}</td>
					                        </tr>
					                        <tr>
					                            <td class="left"><strong>Payment Due</strong></td>
					                            <td class="right">{{purchaseDetails.due}}</td>
					                        </tr>
					                    </tbody>
					                </table>
					            </div>
					        	<div class="col-lg-6 px-3" *ngIf="purchaseDetails.due !=0">
					                <fieldset class="scheduler-border">
                                    	<legend class="scheduler-border">Payment</legend>
					                	<div class="form-group row">
                                            <label for="date" class="col-5 col-form-label">Date <span>*</span> </label>
                                            <div class="col-7">
                                                <div class="input-group">
                                                  <input class="form-control" placeholder="yyyy-mm-dd" name="date" id="date" [(ngModel)]="payment.date" required ngbDatepicker #d="ngbDatepicker">
                                                  <button class="input-group-addon" (click)="d.toggle()" type="button">
                                                    <i class="fa fa-calendar"></i>
                                                  </button>
                                                </div>
                                            </div>
                                        </div>
					                    <div class="form-group row">
					                        <label for="receivedAmount" class="col-5 col-form-label">Received Amount <span>*</span></label>
					                        <div class="col-7">
					                             <input class="form-control" type="number" id="receivedAmount" name="receivedAmount" required [(ngModel)]="payment.receivedAmount"> 
					                        </div>
					                    </div>
					                    <div class="form-group row">
				                            <label for="paymentType" class="col-5 form-control-label">Payment Type <span>*</span></label>
				                            <div class="col-7">
				                                <select class="form-control" type="text" id="paymentType" name="paymentType" required [(ngModel)]="payment.paymentType">
				                                    <option value="" selected>Please Select</option>
				                                    <option *ngFor="let item of paymentTypeList" value="{{item.id}}">{{item.name}}</option>
				                                </select>
				                            </div>
				                        </div>
				                        <button type="submit" class="btn btn-success" [disabled]="!f.form.valid" (click)="paymentUpdate()">Payment</button>
					                </fieldset>
						        </div>
					            <!--/.col-->
					        </div>
					    </div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>