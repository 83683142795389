import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService, DashboardService } from 'src/app/_services';
import { MVFlow } from 'src/app/models/MVFlow';
import { MVFlowDetail } from 'src/app/models/MVFlowDetail';

@Component({
  selector: 'app-mvflowdetail',
  templateUrl: './mvflowdetail.component.html',

})
export class MvflowdetailComponent implements OnInit {

  showloding = true;
  lodingImage = false;
  dataSource: MatTableDataSource<MVFlowDetail>; 

  @Input() mvFlow: MVFlow;
  @Input() stDate: string;
  @Input() endDate: string;

  mvFlowItems: MVFlowDetail[] = [];
  isLoading = false;

  displayedColumns: string[] = ['operDate','qtyIn', 'qtyOut'];


  constructor(private dataService:DashboardService,private alertService: AlertService) { }

  ngOnInit(): void {
    
    this.bindMVFlowDetail();


  }

 

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  
    }
  
    loadHide(){
    this.showloding = false;
    this.lodingImage = true;
    }

    
  createDataSource(source:MVFlowDetail[])
  { 
    this.dataSource=new MatTableDataSource<MVFlowDetail>(source);
  }

  async bindMVFlowDetail() {
    this.loadShow();
    this.createDataSource( null);
    this.dataService.getMVFlowDetail(this.stDate,this.endDate,this.mvFlow.itemName).pipe().subscribe(data => {
        
  
    //console.log(data);
    
    if(data['status']==200)
    {
      this.createDataSource( data.data);
      
      
    }
    else
    {
      this.alertService.error(data['mesg']);
    }
  
      this.loadHide();
  });
  
  }

}
