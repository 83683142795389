import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class PurchasesHistoryService {

  constructor(private http:HttpClient) { }



  getAllPurchases(){
    return this.http.get('/api/get-all-purchases');
  }

  getPurchasesDetails(val){
    return this.http.post('/api/get-purchases-details',{purchase_code:val});
  }
}