import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class PurchasesDetailsService {

  constructor(private http:HttpClient) { }

  getPurchasesDetails(val){
    return this.http.post('/api/get-purchases-details',{id:val});
  }
}