import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'

@Injectable()
export class FormatService {

  constructor() { }

  getFormattedDate(d:Date):string
  {

    var datePipe = new DatePipe("en-US");
    return datePipe.transform(d, 'yyyy-MM-dd');

  }
  getFormattedDate2(d:Date):string
  {

    var datePipe = new DatePipe("en-US");
    return datePipe.transform(d, 'MMM-dd');

  }
  
}
