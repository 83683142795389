import { Component, OnInit } from '@angular/core';
import { INavData } from '@coreui/angular';
import { KeyValueString } from '../shared/common_models';
import { navItems } from '../_nav';
import { AppService, AuthenticationService, SettingService} from '../_services/index';
import { SystemService } from '../_services/system.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {
  userData = {
    profileName:'',
    image:''
  }
  getToken:any;
  showDNClass: boolean = false;
  setting = {image:''};
  LanguageList: Array<KeyValueString> = []; selectedLang = "EN"; selectedLang_Key = "English";
  constructor(public authenticationService: AuthenticationService,
    private dataService:AppService, 
    public service: SystemService,
    public settingsService: SettingService) { 
      this.get_language_list();
    }
  // nav:any;

  public disabled = false;
  public status: {isopen: boolean} = {isopen: false};
  //public navItems = navItems;
  public navItems : INavData[];
  public year: number;

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  ngOnInit(): void {
    setTimeout(() => {
        this.showDNClass = true;
    },20);

    this.getToken = window.localStorage.getItem('currentUser');
    
    this.dataService.checkLogin(this.getToken).pipe()
        .subscribe(data => {
                if(data['user']){
                  this.userData = {
                      profileName:data['user'].name,
                      image:data['user'].image,
                  }
                 
                  this.dataService.getMenu()
                    .pipe().subscribe(
                      menudata => { 
                        
                        this.navItems = menudata['data'];
                    });

                  

                  
                  }
            });
    let settingData = JSON.parse(localStorage.getItem('setting'));
    this.setting = {image:settingData.image};
    this.year=new Date().getFullYear();

          
  }

  async get_language_list() {
    await this.service.get_Languages().pipe().subscribe(data=>
      {
        this.LanguageList = data['data'];
        if (localStorage.getItem("lang")) 
          { this.selectedLang = localStorage.getItem("lang"); }
        this.changeLanguage({ key: this.selectedLang, value: this.selectedLang }, false);
      });

   
   
}
async changeLanguage(item: KeyValueString, IsApiRefresh = true) {
  if (item.value == item.key) { item = this.LanguageList.find(d => d.value == item.value); }

  this.selectedLang = item.value;
  this.selectedLang_Key = item.key;
  localStorage.setItem("lang", item.value);
  this.service.CL = item.value;
  this.service.Translator.use(item.value);
  //setTimeout(() => { this.service.Set_RTL(); })


  if (IsApiRefresh) {
      //IsApiRefresh if this is pass true in signalR 'Get_Language_Refresh' then it could be infinite loop, so be carefull when play with this
        let res = this.settingsService.getTranslate({ params: { lang: item.value }}).pipe().subscribe(data=>
         {
          this.service.Translator.set_data(data['data']);
          //dummy set for reflect new changes            
          this.service.CL = 'dummy';
          setTimeout(() => { this.service.CL = item.value; });

         } );
  }
}

  public logout(): void{
    // remove user from local storage to log user out
    this.authenticationService.logout();
  }


  public sidebarMinimized = false;
  // public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}