import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchasesDuePaymentService {

  constructor(private http:HttpClient) { }

  getInvoiceDetails(val){
    return this.http.post('/api/get-purchases-invoice-details',{id:val});
  }
   
  paymentUpdate(data){
    return this.http.post('/api/purchases-payment-update',data);
  }

}
