import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable()
export class UserRoleService {

  constructor(private http:HttpClient) { }

  getUser(id){
    return this.http.post('/api/user-assing-role-data',{id:id});
  }
  
  getRoleData() {
    return this.http.get('/api/user-role-data');
  }

  addUserRole(menu,submenu,user_id){
    return this.http.post('/api/add-user-role',{menu:menu,subMenu:submenu,userId:user_id});
  }
}
