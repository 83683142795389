import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class CategoryService {

  constructor(private http:HttpClient) { }

  getToken = window.localStorage.getItem('token');


  save(data){
  	return this.http.post('/api/category-save', data);
  }

  getAllCategory(){
    return  this.http.get('/api/get-all-category-by-grid');
  }

  getCategory(id){
    return this.http.post('/api/get-category',{id:id});
  }

  categoryUpdate(data){
    return this.http.post('/api/category-update',data);
  }

  categoryDelete(id){
    return this.http.post('/api/category-delete',{id:id});
  }
}
