import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SubCategoryService {

  constructor(private http:HttpClient) { }

  save(data){
  	return this.http.post('/api/subCategory-save',data);
  }

  getAllSubCategory(){
    return  this.http.get('/api/get-subCategoryGridData');//.map((response: Response) => response.json());
  }

  getCategory()
  {
    return this.http.get('/api/get-all-category');//.map((response: Response) => response.json());
  }


  getSubCategory(id){
    return this.http.post('/api/get-subCategory',{id:id});
  }
  subCategoryUpdate(data){
    return this.http.post('/api/subCategory-update',data);
  }

  subCategoryDelete(id){
     return this.http.post('/api/subCategory-delete',{id:id});
  }

}
